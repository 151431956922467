import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, signal} from '@angular/core';
import {SEOService} from "../../service/seo-service/seo.service";
import AdcmRepository from "../../repositories/adcm/adcm.repository";
import LineUpResponse from "../../types/lineup-response.type";
import {catchError, lastValueFrom, of, Subscription, take} from "rxjs";
import LineUpGroup from "../../types/lineup-group.type";
import {MemberData} from "../profile-page/config/member-data";
import {ManagementBadge} from "../profile-page/config/management-badge";
import {environment} from "../../../../environments/environment";
import {RibbonStatistic} from "../../types/ribbon-statistic";
import {ManagementBadgeStatistic} from "../../types/management-badge-statistic";
import {GroupStatistics} from "../../types/group-statistics";
import {UrlService} from "../../service/url-service/url.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {StatisticsCacheService} from "../../service/statistics-cache-service/statistics-cache.service";
import {RibbonAndBadgesStatistic} from "../../types/RibbonAndBadgesStatistic";
import {EBadge} from "../profile-page/config/e-badge";
import {AirborneTranslationService} from "../../service/airborne-translation-service/airborne-translation.service";

import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {AccordionModule} from "primeng/accordion";
import {TooltipModule} from "primeng/tooltip";
import {LoadingComponent} from "../../shared/loading/loading.component";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";

@Component({
  standalone: true,
  imports: [
    NgIf,
    AccordionModule,
    RouterLink,
    NgForOf,
    NgOptimizedImage,
    TooltipModule,
    LoadingComponent,
    MainLayoutComponent,
    AirCardComponent,
  ],
  selector: 'app-statistics-page',
  templateUrl: './statistics-page.component.html',
  styleUrls: ['./statistics-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsPageComponent implements OnInit, OnDestroy {

  readonly #seoService = inject(SEOService);
  readonly #adcmRepo = inject(AdcmRepository);
  readonly #urlService = inject(UrlService);
  readonly #router = inject(Router);
  readonly #statisticsCacheService = inject(StatisticsCacheService);
  readonly #route = inject(ActivatedRoute);
  readonly #cd = inject(ChangeDetectorRef);
  readonly #airborneTranslationService = inject(AirborneTranslationService);

  loading = signal(true);
  hasError = signal(false);
  activeMembers: MemberData[] = [];
  allMembersWithAlreadyLeftMembers: MemberData[] = [];

  ribbons: RibbonStatistic[] = [];
  managementBadges: ManagementBadgeStatistic[] = [];
  additionalBadges: ManagementBadgeStatistic[] = [];

  lineupData: LineUpResponse;

  statisticMemberCount: number;
  statisticPlatoonCount: number;
  statisticRecruitCount: number;
  statisticZeusCount: number;
  statisticFlexibleZeusCount: number;
  statisticFirstMember: MemberData;
  statisticNewestMember: MemberData;
  statisticManagementBadges: ManagementBadgeStatistic[];
  statisticAdditionalBadges: ManagementBadgeStatistic[];
  statisticRibbons: RibbonStatistic[];
  statisticMostValuableRibbon: RibbonStatistic;
  statisticCompany: GroupStatistics[] = [];

  subscriptions: Subscription[] = [];

  async ngOnInit(): Promise<void> {

    this.#urlService.setPreviousUrl(this.#router.url);

    this.#seoService.setPageTitle('Statistiken');
    this.#seoService.updateKeyWords(['statistiken', 'statistics'])

    await this.buildData();

    this.validateZeus();

    this.loading.set(false);
    this.#cd.markForCheck();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getManagementBadgePath(badge: ManagementBadge): string {
    // TODO rh meh, dafür gibts einen eigenen endpoint, der aber nur mit id aufgerufen werden kann
    return `${environment.baseAdcmUrl}/public-assets/badges/managementBadges/${badge.badge}.png`;
  }

  getAdditionalBadgePath(badge: ManagementBadge): string {
    // TODO rh meh, dafür gibts einen eigenen endpoint, der aber nur mit id aufgerufen werden kann
    return `${environment.baseAdcmUrl}/public-assets/badges/additionalBadges/${badge.badge}.png`;
  }

  toLocaleDateString(dateString: string): string {
    return new Date(dateString).toLocaleDateString();
  }

  getBadgeTranslation(badge: string) {
    return this.#airborneTranslationService.getBadgeTranslation(badge);
  }

  protected buildCompanyStatistics(): void {

    this.statisticCompany.push(this.getGroupStatistics(this.lineupData.company));

    for (const platoon of this.lineupData.company.children) {
      this.statisticCompany.push(this.getGroupStatistics(platoon));
    }
  }

  protected getGroupStatistics(group: LineUpGroup): GroupStatistics {

    const groupStatistic = new GroupStatistics();
    groupStatistic.details = group;
    groupStatistic.currentMembers = 0;
    groupStatistic.maxMembers = 0;

    for (const position of group.positions) {

      groupStatistic.maxMembers++;

      if (position.member) {
        groupStatistic.currentMembers++;
      }
    }

    for (const children of group.children) {
      groupStatistic.currentMembers += this.getGroupStatistics(children).currentMembers;
      groupStatistic.maxMembers += this.getGroupStatistics(children).maxMembers;
    }

    return groupStatistic;
  }

  protected async buildData() {

    await this.useMembersCacheIfExists();
    await this.useLineUpCacheIfExists();

    const resolvedData: RibbonAndBadgesStatistic = this.#route.snapshot.data['ribbonsAndBadges'];
    this.ribbons = resolvedData.ribbons;
    this.managementBadges = resolvedData.managementBadges;
    this.additionalBadges = resolvedData.additionalBadges;

    this.buildStatistics();
  }

  protected buildStatistics() {

    const membersByJoinDate = this.activeMembers.sort((a, b) => Date.parse(a.joinDate) - Date.parse(b.joinDate));
    const ribbonsSortedByCount = this.ribbons.sort((a, b) => b.members.length - a.members.length);

    this.statisticMemberCount = this.activeMembers.length;
    this.statisticPlatoonCount = this.lineupData.company.children.length;
    this.statisticRecruitCount = this.lineupData.recruits.length;
    this.statisticZeusCount = this.lineupData.zeus.length;
    this.statisticFlexibleZeusCount = this.activeMembers.filter(member => member.zeusType === 'ROTATION').length;
    this.statisticFirstMember = membersByJoinDate[0];
    this.statisticNewestMember = membersByJoinDate[membersByJoinDate.length - 1];
    this.statisticManagementBadges = this.sortManagementBadges(this.managementBadges.filter(badge => badge.members.length > 0));
    this.statisticAdditionalBadges = this.additionalBadges.filter(badge => badge.members.length > 0);
    this.statisticRibbons = ribbonsSortedByCount;
    this.statisticMostValuableRibbon = ribbonsSortedByCount[0];

    this.buildCompanyStatistics()
  }

  // Determine wrong zeus assignments, because the mismatch between zeusType as 'ROTATION' and the missing badges fucked me up
  protected validateZeus(): void {

    const wrongZeus = [];

    for (const member of this.activeMembers) {

      const zeusRotationBadge = member.additionalBadges.find(badge => badge.badge === 'ZEUS_ROTATION');
      const zeusPermanentBadge = member.additionalBadges.find(badge => badge.badge === 'ZEUS_PERMANENT');
      const zeusType = member.zeusType;

      // correct type, no badge
      if (zeusType === 'ROTATION' && !zeusRotationBadge) {
        wrongZeus.push(member);
      }

      // correct badge, wrong type
      if (zeusRotationBadge && zeusType !== 'ROTATION') {
        wrongZeus.push(member);
      }

      // correct type, no badge
      if (zeusType === 'PERMANENT' && !zeusPermanentBadge) {
        wrongZeus.push(member);
      }

      // correct badge, wrong type
      if (zeusPermanentBadge && zeusType !== 'PERMANENT') {
        wrongZeus.push(member);
      }
    }

    if (wrongZeus.length > 0) {
      console.log('The following members have a mismatch as zeusType and/or zeus badge', wrongZeus);
    }
  }

  protected async useMembersCacheIfExists() {

    const membersCache = await lastValueFrom(this.#statisticsCacheService.membersCache$.pipe(take(1)));
    if (membersCache) {

      this.activeMembers = membersCache.filter(member => !member.archived);
      this.allMembersWithAlreadyLeftMembers = membersCache;
      console.info('Cache match. Loading cached members.');
    } else {

      const allMembers: MemberData[] = this.#route.snapshot.data['ribbonsAndBadges'].allMembers;

      this.activeMembers = allMembers.filter(member => !member.archived);
      this.allMembersWithAlreadyLeftMembers = allMembers;

      this.#statisticsCacheService.setMembersCache(allMembers);
    }
  }

  protected async useLineUpCacheIfExists() {

    const lineUpCache = await lastValueFrom(this.#statisticsCacheService.lineUpCache$.pipe(take(1)));
    if (lineUpCache) {

      this.lineupData = lineUpCache;
      console.info('Cache match. Loading cached line up.');
    } else {

      this.lineupData = await lastValueFrom(
        this.#adcmRepo.getCurrentLineUp().pipe(
          catchError(error => {

            console.error(error);
            this.hasError.set(true);
            return of( new LineUpResponse() )
          }),
        )
      );

      this.#statisticsCacheService.setLineUpCache(this.lineupData);
    }
  }

  protected sortManagementBadges(badges: ManagementBadgeStatistic[]): ManagementBadgeStatistic[] {

    const sortedBadges: ManagementBadgeStatistic[] = [ // TODO rh crappy way to find the first badge, but array.find can return undefined - only to satisfy the compiler
      badges.filter(badge => badge.badge.badge === EBadge.ADDONS_SENIOR)[0],
      badges.filter(badge => badge.badge.badge === EBadge.INSTRUCTOR_SENIOR)[0],
      badges.filter(badge => badge.badge.badge === EBadge.IT_SENIOR)[0],
      badges.filter(badge => badge.badge.badge === EBadge.MISSION_SENIOR)[0],
      badges.filter(badge => badge.badge.badge === EBadge.RECRUITER_SENIOR)[0],
      badges.filter(badge => badge.badge.badge === EBadge.PR_SENIOR)[0],
      badges.filter(badge => badge.badge.badge === EBadge.ZEUS_PERMANENT)[0],
      badges.filter(badge => badge.badge.badge === EBadge.ADDONS)[0],
      badges.filter(badge => badge.badge.badge === EBadge.COUNCIL)[0],
      badges.filter(badge => badge.badge.badge === EBadge.INSTRUCTOR)[0],
      badges.filter(badge => badge.badge.badge === EBadge.IT)[0],
      badges.filter(badge => badge.badge.badge === EBadge.MISSION)[0],
      badges.filter(badge => badge.badge.badge === EBadge.PR)[0],
      badges.filter(badge => badge.badge.badge === EBadge.RECRUITER)[0],
      badges.filter(badge => badge.badge.badge === EBadge.ZEUS_ROTATION)[0],
    ].filter(Boolean);

    for (const badge of badges) { // add badges that are not already caught by the array

      const badgeAlreadyExists = sortedBadges.find(sortedBadge => sortedBadge.badge.badge === badge.badge.badge);
      if (!badgeAlreadyExists) {
        sortedBadges.push(badge);
      }
    }

    return sortedBadges;
  }
}
