import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import LineUpResponse from '../../types/lineup-response.type';

@Injectable()
export class LineupCacheService {

  private lineUpCache = new BehaviorSubject<LineUpResponse | null>(null);
  public lineUpCache$ = this.lineUpCache.asObservable();

  constructor() { }

  setLineUp(lineUp: LineUpResponse) {
    this.lineUpCache.next(lineUp);
  }
}
