export default {
  home: '',
  einheit: {
    name: 'unit',
    children: {
      aufstellung: {
        name: 'lineup',
        full: '/unit/lineup'
      },
      abteilungen: {
        name: 'departments',
        full: '/unit/departments'
      },
      raenge: {
        name: 'ranks',
        full: '/unit/ranks'
      },
      organigram: {
        name: 'organigram',
        full: '/unit/organigram'
      }
    }
  },
  rekrutierung: 'recruiting',
  ausbildung: {
    name: 'training',
    children: {
      mentorensystem: {
        name: 'mentorsystem',
        full: '/training/mentorsystem'
      },
      mentorenliste: {
        name: 'mentorlist',
        full:'/training/mentorlist'
      },
      module: {
        name: 'modules',
        full: '/training/modules'
      },
      sanitaetshandbuch: '/training/medical',
      feldhandbuch: {
        index: '/fieldguide',
        module1: '/fieldguide/module1',
        module2: '/fieldguide/module2',
        module3: '/fieldguide/module3',
        module4: '/fieldguide/module4'
      },
      communications: {
        name: 'communications',
        full:'/training/communications'
      },
    }
  },
  blog: 'blog',
  profile: '/profile',
  forum: '/community',
  join: 'https://airborne-division.de/community/forum/board/7-bewerbungen/',
  events: '/community/calendar',
  medien: 'media',
  statistics: 'statistics',
  discord: 'discord',
  datenschutz: 'privacy',
  impressum: 'imprint',
  Error404: '404'
};
