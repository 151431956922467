import {Translation, TranslationValue} from "../../types/translation.type";
import {Injectable} from "@angular/core";
import {differenceInYears, parseISO} from "date-fns";

@Injectable()
export class AirborneTranslationService {

  // TODO auslagern
  readonly #ranks = new Map<string, { name: string, description?: string }>([
    ['PV1', { name: 'Private/Recruit' } ],
    ['PV2', { name: 'Private'} ],
    ['PFC', { name: 'Private First Class' } ],
    ['SPC', { name: 'Specialist' } ],
    ['CPL', { name: 'Corporal' } ],
    ['SGT', { name: 'Sergeant' } ],
    ['SSG', { name: 'Staff Sergeant' } ],
    ['SFC', { name: 'Sergeant First Class' } ],
    ['MSG', { name: 'Master Sergeant' } ],
    ['1SG', { name: 'First Sergeant' } ],
    ['2LT', { name: 'Second Lieutenant' } ],
    ['1LT', { name: 'First Lieutenant' } ],
    ['CPT', { name: 'Captain' } ],
    ['ZEUS', { name: 'Operations Sergeant', description: 'Dieses Mitglied ist aktuell als permanenter ZEUS eingesetzt.' } ], // TODO muss aktuell leider hier mit in die liste
  ]);

  readonly #positions = new Map<string, { name: string, description?: string }>([
    ['PMED', { name: 'Platoon Medic' } ],
    ['CO', { name: 'Company Officer' } ],
    ['PL', { name: 'Platoon Leader' } ],
    ['PSG', { name: 'Platoon Sergeant' } ],
    ['RTO', { name: 'Radio Telephone Operator' } ],
    ['SL', { name: 'Squad Leader' } ],
    ['TL', { name: 'Team Leader' } ],
    ['AR', { name: 'Automatic Rifleman' } ],
    ['GR', { name: 'Grenadier' } ],
    ['RM', { name: 'Rifleman' } ],
    ['DMR', { name: 'Designated Marksman' } ],
    ['MG', { name: 'Machine Gunner' } ],
    ['AG', { name: 'Assistant Gunner' } ],
    ['AB', { name: 'Ammo Bearer' } ],
    ['AT', { name: 'Anti Tank' } ],
    ['AH', { name: 'Ammo Handler' } ],
    ['MED', { name: 'Combat Medic' } ],
    ['CMED', { name: 'Company Medic' } ],
    ['SCL', { name: 'Section Leader' } ],
    ['GUN', { name: 'Gunner' } ],
    ['SRTO', { name: 'Senior Radio Telephone Operator' } ],
    ['1SG', { name: 'First Sergeant' } ],
    ['SUPSGT', { name: 'Supply Sergeant' } ],
    ['OPSGT', { name: 'Operations Sergeant' } ],
  ]);

  readonly #badges = new Map<string, { name: string, description?: string }>([
    ['CIB1', { name: 'Combat Infantryman', description: 'Das Mitglied hat mindestens eine Kampagne mit uns abgeschlossen und erhält deswegen den Combat Infantryman Badge.'}],
    ['CIB2', { name: 'Combat Infantryman (zweite Verlehung)', description: 'Das Mitglied hat mindestens 3 Kampagnen mit uns abgeschlossen und erhält deswegen den Combat Infantryman Badge (zweite Verleihung).'}],
    ['CIB3', { name: 'Combat Infantryman (dritte Verlehung)', description: 'Das Mitglied hat mindestens 5 Kampagnen mit uns abgeschlossen und erhält deswegen den Combat Infantryman Badge (dritte Verleihung).'}],
    ['CIB4', { name: 'Combat Infantryman (vierte Verlehung)', description: 'Das Mitglied hat mindestens 8 Kampagnen mit uns abgeschlossen und erhält deswegen den Combat Infantryman Badge (vierte Verleihung).'}],
    ['ZEUS_PERMANENT', { name: 'Fester Zeus', description: 'Das Mitglied dient/diente als fester Zeus in den wöchentlichen Missionen. Es ist ein Grundpfeiler unserer Gemeinschaft.'}],
    ['ZEUS_ROTATION', { name: 'Zeus in Rotation', description: 'Das Mitglied dient/diente als Zeus in wöchentlicher Rotation. Es ist ein Grundpfeiler unserer Gemeinschaft.'}],
    ['ZEUS_ROTATION', { name: 'Zeus in Rotation', description: 'Das Mitglied dient/diente als Zeus in wöchentlicher Rotation. Es ist ein Grundpfeiler unserer Gemeinschaft.'}],
    ['COUNCIL', { name: 'Clanrat', description: 'Das Mitglied war über eine Periode hinweg Teil des Clanrates.'}], // TODO muss weg
    ['INSTRUCTOR', { name: 'Instructor', description: 'Das Mitglied führt eigenständig komplette Trainingsmodule durch.'}],
    ['INSTRUCTOR_SENIOR', { name: 'Senior Instructor', description: 'Das Mitglied führt seit mehr als zwei Jahren eigenständig komplette Trainingsmodule durch.'}],
    ['RECRUITER', { name: 'Recruiter', description: 'Das Mitglied führt eigenständig Kennenlerngespräche mit Bewerbern.'}],
    ['RECRUITER_SENIOR', { name: 'Senior Recruiter', description: 'Das Mitglied hat eigenständig mindestens 50 Kennenlerngespräche mit Bewerbern geführt.'}],
    ['MISSION', { name: 'Missionsbau', description: 'Das Mitglied beteiligt sich am wöchentlichen Missionsbau. Es hat bei mindestens 5 Missionen als Missionsverantwortlicher oder Missionsbauer mitgewirkt. Alternativ dazu sind 3 Beteiligungen im Story Team im Rahmen der Erstellung eines Operationskonzeptes.'}],
    ['MISSION_SENIOR', { name: 'Senior Missionsbau', description: 'Das Mitglied beteiligt sich am wöchentlichen Missionsbau. Es hat bei mindestens 30 Missionen als Missionsverantwortlicher oder Missionsbauer mitgewirkt. Alternativ dazu sind 15 Beteiligungen im Story Team im Rahmen der Erstellung eines Operationskonzeptes.'}],
    ['IT', { name: 'IT & Software Development', description: 'Das Mitglied beteiligt sich an der Wartung und Weiterentwicklung der IT Infrastruktur.'}],
    ['IT_SENIOR', { name: 'Senior IT & Software Development', description: 'Das Mitglied beteiligt sich seit mehr als zwei Jahren an der Wartung und Weiterentwicklung der IT Infrastruktur.'}],
    ['ADDONS', { name: 'Addons & Scripting', description: 'Das Mitglied beteiligt sich an der Pflege unserer Mod Repositories oder an der Entwicklung eigener Mods für die 101st. Airborne Division.'}],
    ['ADDONS_SENIOR', { name: 'Senior Addons & Scripting', description: 'Das Mitglied beteiligt sich seit mehr als zwei Jahren an der Pflege unserer Mod Repositories oder an der Entwicklung eigener Mods für die 101st. AD.'}],
    ['PR', { name: 'Public Relations', description: 'Das Mitglied kümmert sich um den Kontakt mit anderen Clans oder pflegt die Medienauftritte der 101st. Airborne Division.'}],
    ['PR_SENIOR', { name: 'Senior Public Relations', description: 'Das Mitglied kümmert sich seit mehr als zwei Jahren um den Kontakt mit anderen Clans oder pflegt die Medienauftritte der 101st. Airborne Division.'}],
  ]);

  readonly #misc = new Map<string, { name: string, description?: string }>([
    ['flag', { name: 'Die Flagge unserer Company. Zu sehen ist das Abzeichen der dritten Brigade der 101st Airborne Division (Rakkansan). Darunter zu sehen sind Elemente der Regimentswappen der Teileinheiten unserer Company. Der weiße Blitz für das 506 Infantry Regiment und das weiße Quadrat auf grünem Grund für das 33 Cavalry Regiment. Es handelt sich bei dem Logo um eine Eigenkreation.' } ],
    ['patch', { name: 'Das Abzeichen der 101st Airborne Division' } ],
  ]);

  getRanksTranslation(rank: string): TranslationValue {

    const translation = this.#ranks.get(rank);

    if (!translation) {

      console.warn('No translation found for rank:', rank);

      const backupTranslation = new TranslationValue();
      backupTranslation.name = '';

      return backupTranslation;
    }

    const rankTranslation = new TranslationValue();
    rankTranslation.name = translation.name;

    return rankTranslation;
  }

  getPositionTranslation(position: string): TranslationValue {

    const translation = this.#positions.get(position);

    if (!translation) {

      console.warn('No translation found for position:', position);

      const backupTranslation = new TranslationValue()
      backupTranslation.name = 'Noch keine Position';

      return backupTranslation;
    }

    const positionTranslation = new TranslationValue();
    positionTranslation.name = translation.name;

    return positionTranslation;
  }

  getBadgeTranslation(badge: string): TranslationValue {

      const translation = this.#badges.get(badge);

      if (!translation) {

        console.warn('No translation found for badge:', badge);

        const backupTranslation = new TranslationValue()
        backupTranslation.name = '';
        backupTranslation.description = '';

        return backupTranslation;
      }

      const badgeTranslation = new TranslationValue();
      badgeTranslation.name = translation.name;
      badgeTranslation.description = translation.description;

      return badgeTranslation;
  }

  getStripeDescription(joinDate: string): string {

    if (joinDate) {
      let timeDifference = differenceInYears(
        Date.now(),
        parseISO(joinDate)
      );
      let numberOfStripes = Math.floor(timeDifference / 2);

      switch (numberOfStripes) {
        case 0: {
          return 'Das Mitglied ist seit weniger als 2 Jahren bei uns und erhält deswegen noch keinen Service Stripe auf dem linken Ärmel.';
        }

        case 1: {
          return 'Das Mitglied ist seit mehr als zwei Jahren bei uns und erhält deswegen einen Service Stripe auf dem linken Ärmel.';
        }

        default: {
          return (
            'Das Mitglied ist seit mehr als ' +
            timeDifference +
            ' Jahren bei uns und erhält deswegen ' +
            numberOfStripes +
            ' Service Stripes auf dem linken Ärmel.'
          );
        }
      }
    }

    return '';
  }
}
