import {Component, Input, signal} from '@angular/core';
import { AirCardComponent } from '../air-card/air-card.component';
import { MainLayoutComponent } from '../layouts/main-layout/main-layout.component';
import { NgIf, NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'air-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: true,
    imports: [NgIf, MainLayoutComponent, AirCardComponent, NgOptimizedImage]
})
export class LoadingComponent {

  @Input() isLoading = false;

}
