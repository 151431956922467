import { Component, Input, OnInit } from '@angular/core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import paths from 'src/paths';
import Department from '../../types/department.type';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

import {NgForOf, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@Component({
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgForOf,
    AirCardComponent,
    FontAwesomeModule
  ],
  selector: 'air-department-card',
  templateUrl: './department-card.component.html',
  styleUrls: ['./department-card.component.scss']
})
export class DepartmentCardComponent implements OnInit {
  @Input() title: String | undefined;
  @Input() description: String | undefined;
  @Input() departmentData: Department | undefined;

  faUser = faUser as IconDefinition;
  get paths() {
    return paths;
  }

  constructor() {}

  ngOnInit(): void {}
}
