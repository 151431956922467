<air-main-layout>
  <div class="grid w-full">
    <div class="col">
      <air-card>
        <h1 class="text-center air-card-title mb-0">
          Willkommen bei der 101st Airborne Division
        </h1>

        <div class="grid mt-2">
          <div class="col-12 md:col-6 air-dual-column-left">
            <p class="text-justify mb-2">
              Für die Mitgliedschaft bei der 101st Airborne Division gibt es
              außer Lernbereitschaft, Disziplin und Interesse an MilSim nur
              wenige Voraussetzungen.
            </p>
            <ul class="air-list ml-2 mb-2">
              <li>
                <fa-icon class="mr-1" [icon]="faChevronRight"></fa-icon>
                Mindestalter von
                <b>18 Jahren</b>
              </li>
              <li>
                <fa-icon class="mr-1" [icon]="faChevronRight"></fa-icon> Arma 3
                ohne DLC's
              </li>
              <li>
                <fa-icon class="mr-1" [icon]="faChevronRight"></fa-icon>
                Mikrofon und Teamspeak
              </li>
              <li class="flex">
                <span>
                  <fa-icon class="mr-1" [icon]="faChevronRight"></fa-icon>
                </span>
                <span class="inline-block ml-1">
                  Etwas Zeit für die Missionen am Freitagabend, sowie für die
                  Trainingseinheiten unter der Woche
                </span>
              </li>
            </ul>
            <p class="mb-0">
              Eine Mitgliedschaft bei der 101st Airborne Division ist komplett
              <b>kostenlos</b>.
            </p>
          </div>
          <div class="col-12 md:col-6 air-dual-column-right">
            <img
              class="air-image"
              ngSrc="./assets/images/join-us-stylistic.jpg"
              alt="Aufstellung der Kompanie im Training"
              height="478"
              width="850"
              [priority]="true"
            />
          </div>
        </div>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col">
      <air-card headerTitle="Deine Bewerbung">
        <p class="text-justify">
          Falls du diese Voraussetzungen erfüllst, kannst du in unserem Forum
          eine Bewerbung verfassen. Im Bereich “Bewerbungen” findest du eine
          Vorlage. Wir bitten dich diese für deine Bewerbung zu benutzen. Das
          ermöglicht uns eine schnellere Bearbeitung der Bewerbungen.
        </p>

        <p class="text-justify">
          Nach deiner Bewerbung erfolgt ein persönliches Gespräch mit einem
          Recruiter, bei welchem Detailfragen geklärt werden, sodass wir uns ein
          Bild von dir machen können. Sollte dieses Gespräch positiv verlaufen,
          steht einem erfolgreichen Start bei der 101st Airborne Division nichts
          mehr im Wege.
        </p>

        <p class="text-justify">
          Beachte: Um deine Bewerbung im Forum zu verfassen ist eine
          Registrierung nötig.
        </p>

        <p class="text-justify">
          Falls du Fragen hast, kannst du uns direkt im Discord kontaktieren
          oder deine Fragen im Forum stellen.
        </p>

        <span class="flex justify-content-center mt-2 .air-departments-buttons">
          <air-button class="mr-1" [link]="paths.join">
            Bewerbung im Forum
          </air-button>
          <air-button [link]="paths.discord"> Discord</air-button>
        </span>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col">
      <air-card headerTitle="Die Airborne Division">
        <p class="text-justify">
          Um uns näher kennenzulernen, kannst du die Seite “Wer wir sind”
          aufrufen und dich informieren, wie unser Clan funktioniert, was uns
          antreibt und weshalb wir Spaß am Spiel haben. Wir möchten jeden
          Interessenten bitten, dies als Erstes zu tun.
        </p>

        <span class="flex justify-content-center mt-2">
          <air-button [link]="paths.home">
            Erfahre mehr - Wer wir sind
          </air-button>
        </span>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col-12 md:col-6 air-outer-dual-column-left">
      <air-card class="air-card-fill-h">
        <img
          class="air-image"
          ngSrc="./assets/images/Main_composition_00817.jpg"
          alt="Besprechung während einer Mission"
          height="478"
          width="850"
          [priority]="true"
        />
        <p class="text-justify">
          Als Rekrut in der 101st Airborne Division wirst du natürlich nicht dir
          selbst überlassen. Du bekommst von uns einen Mentor zugewiesen, der
          neben den Ausbildungsverantwortlichen stets als Ansprechpartner für
          dich zur Verfügung steht. Des Weiteren kümmert sich dein Mentor darum,
          dass du in der Lage bist auf unseren Server zu verbinden und
          erleichtert dir den Start bei uns mit einem circa einstündigen
          Trainingsmodul. Danach findet deine weitere Grundausbildung in unseren
          wöchentlichen Trainings statt.
        </p>
        <span class="flex justify-content-center mt-2">
          <air-button [link]="paths.ausbildung.children.mentorensystem.full">
            Das Mentorensystem
          </air-button>
        </span>
      </air-card>
    </div>
    <div class="col-12 md:col-6 air-outer-dual-column-right">
      <air-card class="air-card-fill-h">
        <img
          class="air-image"
          ngSrc="./assets/images/Main_composition_00709_1.jpg"
          alt="Schulung während eines Trainings"
          height="478"
          width="850"
          [priority]="true"
        />
        <p class="text-justify">
          Nachdem dein Mentor dich in die absoluten Grundzüge unserer
          Lerninhalte eingewiesen hat, wird deine Ausbildung in fünf weiteren
          Modulen vertieft. Das Training findet wöchentlich statt und sobald ein
          Trainingszyklus beendet wurde beginnt ein neuer. Für dich als Rekrut
          ist es jedoch unerheblich, zu welchem Zeitpunkt du in den Zyklus
          einsteigst, da die Module inhaltlich abgeschlossen sind. Nachdem du
          jedes Modul einmal besucht hast, wirst du zu einer abschließenden
          Prüfung zugelassen, die das Ende deiner Grundausbildung darstellt.
        </p>
        <span class="flex justify-content-center mt-2">
          <air-button [link]="paths.ausbildung.children.module.full">
            Die Trainingsmodule
          </air-button>
        </span>
      </air-card>
    </div>
  </div>
</air-main-layout>
