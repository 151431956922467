import {ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {catchError, lastValueFrom, map, of, take, tap} from 'rxjs';
import AdcmRepository from '../../repositories/adcm/adcm.repository';
import {SEOService} from '../../service/seo-service/seo.service';
import {UrlService} from '../../service/url-service/url.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LineupCacheService} from '../../service/lineup-cache-service/lineup-cache.service';
import LineUpGroup from "../../types/lineup-group.type";
import {MemberData} from "../profile-page/config/member-data";
import LineUpResponse from "../../types/lineup-response.type";

import {NgClass, NgForOf, NgIf} from "@angular/common";
import {LineUpItemParagraphComponent} from "../../components/line-up-item-paragraph/line-up-item-paragraph.component";
import {LineUpLeaderCardComponent} from "../../components/line-up-leader-card/line-up-leader-card.component";
import {LineUpSquadCardComponent} from "../../components/line-up-squad-card/line-up-squad-card.component";
import {LoadingComponent} from "../../shared/loading/loading.component";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {AirButtonComponent} from "../../shared/air-button/air-button.component";

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    NgClass,
    LineUpItemParagraphComponent,
    LineUpLeaderCardComponent,
    LineUpSquadCardComponent,
    LoadingComponent,
    MainLayoutComponent,
    AirCardComponent,
    AirButtonComponent,
  ],
  selector: 'air-lineup-page',
  templateUrl: './lineup-page.component.html',
  styleUrls: ['./lineup-page.component.scss'],
})
export class LineupPageComponent implements OnInit {

  readonly #adcmRepo = inject(AdcmRepository);
  readonly #seoService = inject(SEOService);
  readonly #urlService = inject(UrlService);
  readonly #router = inject(Router);
  readonly #lineUpCacheService = inject(LineupCacheService);
  readonly #cd = inject(ChangeDetectorRef);
  readonly #activatedRoute = inject(ActivatedRoute);

  readonly loading = signal(true);
  readonly hasError = signal(false);
  readonly company = signal(new LineUpGroup())
  readonly recruits = signal<MemberData[]>([])
  readonly zeus = signal<MemberData[]>([])
  readonly tabState = signal<string>('lineup');

  ngOnInit() {

    this.#urlService.setPreviousUrl(this.#router.url);

    this.loadData();
    this.setViewSearchParam();
    this.#seoService.setPageTitle('Aufstellung');
    this.#seoService.updateKeyWords(['aufstellung', 'lineup'])

  }

  loadData() {
      this.#lineUpCacheService.lineUpCache$
        .pipe(
          take(1),
          map(async cachedLineUp => {

            if (cachedLineUp) {

              if (!cachedLineUp.zeus && !cachedLineUp.recruits && !cachedLineUp.company) this.hasError.set(true);

              return cachedLineUp;
            }

            return await lastValueFrom(
              this.#adcmRepo.getCurrentLineUp().pipe(
                take(1),
                catchError(error => {

                    console.error(error);
                    this.hasError.set(true);
                    return of( new LineUpResponse() )
                }),
                tap(lineUp => this.#lineUpCacheService.setLineUp(lineUp)
              )
            ));
          }),
          tap(async lineUp => {

            const lineUpData = await lineUp;

            this.company.update(() => lineUpData.company);
            this.recruits.update(() => lineUpData.recruits);
            this.zeus.update(() => lineUpData.zeus);

            this.loading.set(false);
            this.#cd.markForCheck();
          }),
      ).subscribe();
  }

  protected setViewSearchParam() {

    const tabState = this.#activatedRoute.snapshot.queryParams['view'];

    switch(tabState) {
      case 'ranks':
        this.tabState.set('ranks');
        break;
      case 'lineup':
      default:
        this.tabState.set('lineup');
    }

    this.#cd.markForCheck();
  }
}
