import { Component, OnInit } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import paths from 'src/paths';
import {SEOService} from '../../service/seo-service/seo.service';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

import {NgOptimizedImage} from "@angular/common";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {AirButtonComponent} from "../../shared/air-button/air-button.component";

@Component({
  standalone: true,
  imports: [
    NgOptimizedImage,
    MainLayoutComponent,
    AirCardComponent,
    FontAwesomeModule,
    AirButtonComponent
  ],
  selector: 'air-recruiting-page',
  templateUrl: './recruiting-page.component.html',
  styleUrls: ['./recruiting-page.component.scss']
})
export class RecruitingPageComponent implements OnInit {
  get paths() {
    return paths;
  }

  faChevronRight = faChevronRight as IconDefinition;

  constructor(protected seoService: SEOService) {}

  ngOnInit(): void {

    this.seoService.setPageTitle('Rekrutierung');
    this.seoService.updateKeyWords(['rekrutierung', 'recruiting'])
  }
}
