import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import AdcmRepository from '../repositories/adcm/adcm.repository';
import {MemberData} from "../pages/profile-page/config/member-data";
import {RibbonStatistic} from "../types/ribbon-statistic";

@Injectable()
export class RibbonDataResolver {

  readonly #adcmRepo = inject(AdcmRepository);
  readonly #router = inject(Router);

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const ribbonId = route.paramMap.get('id');

    if (!ribbonId) {
      return;
    }

    const members = await this.getRibbonMembers(ribbonId);

    if (members.length === 0) {

      console.warn('Wrong ribbon id given. Routing to statistics.');
      this.#router.navigate(['/', 'statistics']);
      return;
    }

    const ribbon = new RibbonStatistic();
    ribbon.members = members;
    ribbon.ribbon = members[0].ribbons.filter(ribbon => ribbon.id === parseInt(ribbonId))[0]; // TODO rh crappy way to find the first ribbon, but array.find can return undefined

    return ribbon;
  }

  protected async getRibbonMembers(ribbonId: string): Promise<MemberData[]> {

    const allMembers = await lastValueFrom(this.#adcmRepo.getAllMemberInfo());

    return allMembers.filter(member => member.ribbons.find(ribbon => ribbon.id === parseInt(ribbonId), 10));
  }

}
