import {Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2} from '@angular/core';
import { DOCUMENT, NgFor, NgIf } from '@angular/common';
import {HeaderMenu} from '../config/header-menu';
import {BehaviorSubject, Observable, of} from "rxjs";
import { RouterLink } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';

@Component({
    selector: 'air-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    standalone: true,
    imports: [SidebarModule, NgFor, NgIf, RouterLink]
})
export class MobileMenuComponent implements OnInit {

  @Input() sidebarVisible$: BehaviorSubject<boolean>;
  @Input() config: HeaderMenu;
  @Output() isSidebarClosedEvent = new EventEmitter<boolean>();

  isSidebarOpen = false;

  constructor(
    protected renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.sidebarVisible$.subscribe(e => this.isSidebarOpen = e)
  }

  // Prevent background scrolling if sidebar is open
  switchScrollLock(state: boolean) {

    switch (state) {
      case true: return this.renderer.addClass(this.document.body, 'no-scroll');
      case false: return this.renderer.removeClass(this.document.body, 'no-scroll');
    }
  }

  closeSidebar() {

    this.sidebarVisible$.next(false);
    this.switchScrollLock(false);
  }
}
