<air-main-layout>
  <div class="grid w-full">
    <div class="col">
      <air-card>
        <div class="grid">
          <div class="col-12 md:col-6">
            <img
              class="air-image"
              ngSrc="./assets/images/YouTube_Logo_2017.svg"
              height="50"
              width="70"
              alt="YouTube Logo"
              style="max-width: 337px; height: 75px;"
              [priority]="true"
            />

            <ul class="air-list">
              <li>
                <air-button
                  class="air-media-link youtube"
                  [size]="ButtonSize.Normal"
                  link="https://www.youtube.com/channel/UCUgonIPFQIiN2vdTRIX1-yg"
                >
                  <fa-icon class="mr-2" [icon]="faYoutube"></fa-icon>
                  SmartTactics
                </air-button>
              </li>
              <li>
                <air-button
                  class="air-media-link youtube"
                  [size]="ButtonSize.Normal"
                  link="https://www.youtube.com/channel/UCqTAOzWEMaBnUIfvdwt158g"
                >
                  <fa-icon class="mr-2" [icon]="faYoutube"></fa-icon>
                  Pipsqueak
                </air-button>
              </li>

              <li>
                <air-button
                  class="air-media-link youtube"
                  [size]="ButtonSize.Normal"
                  link="https://www.youtube.com/channel/UCVZzUM389zv09lSl3AMAirw/featured"
                >
                  <fa-icon class="mr-2" [icon]="faYoutube"></fa-icon> Joker
                </air-button>
              </li>

              <li>
                <air-button
                  class="air-media-link youtube"
                  [size]="ButtonSize.Normal"
                  link="https://www.youtube.com/channel/UCwUd15Ta_WZ_z-zqgGKCStg"
                >
                  <fa-icon class="mr-2" [icon]="faYoutube"></fa-icon> Jason
                </air-button>
              </li>
            </ul>
          </div>
          <div class="col-12 md:col-6">
            <img
              class="air-image"
              ngSrc="./assets/images/Twitch_logo_(wordmark_only).svg"
              height="50"
              width="151"
              alt="Twitch Logo"
              style="max-width: 228px; height: 75px;"
            />

            <ul class="air-list mb-0">
              <li>
                <air-button
                  class="air-media-link twitch"
                  [size]="ButtonSize.Normal"
                  link="https://www.twitch.tv/smarttactics"
                >
                  <fa-icon class="mr-2 flex" [icon]="faTwitch"></fa-icon>
                  SmartTactics
                </air-button>
              </li>

              <li>
                <air-button
                  class="air-media-link twitch"
                  [size]="ButtonSize.Normal"
                  link="https://www.twitch.tv/der_hohn_einer_lampe"
                >
                  <fa-icon class="mr-2 flex" [icon]="faTwitch"></fa-icon>
                  Pipsqueak
                </air-button>
              </li>

              <li>
                <air-button
                  class="air-media-link twitch"
                  [size]="ButtonSize.Normal"
                  link="https://www.twitch.tv/derhobbygeneral"
                >
                  <fa-icon class="mr-2 flex" [icon]="faTwitch"></fa-icon>
                  Hobbygeneral
                </air-button>
              </li>

              <li>
                <air-button
                  class="air-media-link twitch"
                  [size]="ButtonSize.Normal"
                  link="https://www.twitch.tv/asathor456"
                >
                  <fa-icon class="mr-2 flex" [icon]="faTwitch"></fa-icon>
                  Asathor456
                </air-button>
              </li>

              <li>
                <air-button
                  class="air-media-link twitch"
                  [size]="ButtonSize.Normal"
                  link="https://www.twitch.tv/statuefahri"
                >
                  <fa-icon class="mr-2 flex" [icon]="faTwitch"></fa-icon>
                  Fahri
                </air-button>
              </li>
            </ul>
          </div>
        </div>
        <p-divider></p-divider>
        <air-button
          class="air-media-link twitter"
          link="https://twitter.com/101STMILSIM"
          [size]="ButtonSize.Normal"
        >
          <fa-icon class="mr-2" [icon]="faTwitter"></fa-icon> Die 101st Airborne
          Division auf Twitter
        </air-button>
      </air-card>
    </div>
  </div>

  <air-loading [isLoading]="isLoading"></air-loading>
  <div class="grid w-full">
    <div class="col">
      <air-card>
        <div class="inline-flex flex-wrap justify-content-around">
          <div *ngIf="!hasScreenshots">Zurzeit keine Screenshots.</div>
          <div *ngFor="let img of galleryImages$ | async" class="air-gallery-image">
            <p-image [src]="'../../../gallery/' + img" [preview]="true">
              <ng-template pTemplate="indicator">
                <fa-icon [icon]="faSearchPlus"></fa-icon>
              </ng-template>
            </p-image>
          </div>
        </div>
      </air-card>
    </div>
  </div>

  <div class="w-full text-center mt-2 mb-2 opacity-20">
    <img
      ngSrc="./assets/icons/US_101st_Airborne_Division_patch-1.svg"
      height="200"
      alt="Airborne Division Logo"
      width="137"
    />
  </div>
</air-main-layout>
