import {Route} from "@angular/router";
import {LandingPageComponent} from "./app/modules/pages/landing-page/landing-page.component";
import paths from "./paths";
import {DiscordPageComponent} from "./app/modules/pages/discord-page/discord-page.component";
import {PrivacyPageComponent} from "./app/modules/pages/privacy-page/privacy-page.component";
import {ImprintPageComponent} from "./app/modules/pages/imprint-page/imprint-page.component";
import {LineupPageComponent} from "./app/modules/pages/lineup-page/lineup-page.component";
import {Lineup2PageComponent} from "./app/modules/pages/lineup2-page/lineup2-page.component";
import {ProfilePageComponent} from "./app/modules/pages/profile-page/profile-page.component";
import {MemberDataResolver} from "./app/modules/resolver/member-data.resolver";
import {MentorsystemPageComponent} from "./app/modules/pages/mentorsystem-page/mentorsystem-page.component";
import {ModulesPageComponent} from "./app/modules/pages/modules-page/modules-page.component";
import {MentorlistPageComponent} from "./app/modules/pages/mentorlist-page/mentorlist-page.component";
import {CommunicationPageComponent} from "./app/modules/pages/communication-page/communication-page.component";
import {RecruitingPageComponent} from "./app/modules/pages/recruiting-page/recruiting-page.component";
import {DepartmentsPageComponent} from "./app/modules/pages/departments-page/departments-page.component";
import {MediaPageComponent} from "./app/modules/pages/media-page/media-page.component";
import {StatisticsPageComponent} from "./app/modules/pages/statistics-page/statistics-page.component";
import {RibbonAndBadgeResolver} from "./app/modules/resolver/ribbon-and-badge-resolver.service";
import {RibbonPageComponent} from "./app/modules/pages/ribbon-page/ribbon-page.component";
import {RibbonDataResolver} from "./app/modules/resolver/ribbon-data-resolver.service";
import {ManagementBadgePageComponent} from "./app/modules/pages/management-badge-page/management-badge-page.component";
import {ManagementBadgeDataResolver} from "./app/modules/resolver/management-badge-data-resolver.service";
import {AdditionalBadgePageComponent} from "./app/modules/pages/additional-badge-page/additional-badge-page.component";
import {AdditionalBadgeDataResolver} from "./app/modules/resolver/additional-badge-data-resolver.service";
import {ErrorPageComponent} from "./app/modules/pages/error-page/error-page.component";

export const routes: Route[] = [
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: paths.discord,
    component: DiscordPageComponent,
  },
  {
    path: paths.datenschutz,
    component: PrivacyPageComponent,
  },
  {
    path: paths.impressum,
    component: ImprintPageComponent,
  },
  {
    path: paths.einheit.children.aufstellung.name,
    component: LineupPageComponent,
  },
  {
    path: 'lineup2',
    component: Lineup2PageComponent,
  },
  {
    path: 'profile/:name',
    component: ProfilePageComponent,
    resolve: {
      memberData: MemberDataResolver,
    },
  },
  {
    path: paths.ausbildung.name,
    children: [
      {
        path: paths.ausbildung.children.mentorensystem.name,
        component: MentorsystemPageComponent,
      },
      {
        path: paths.ausbildung.children.module.name,
        component: ModulesPageComponent,
      },
      {
        path: paths.ausbildung.children.mentorenliste.name,
        component: MentorlistPageComponent,
      },
      {
        path: paths.ausbildung.children.communications.name,
        component: CommunicationPageComponent,
      },
    ]
  },
  {
    path: paths.rekrutierung,
    component: RecruitingPageComponent,
  },
  {
    path: 'rekrutierungscenter',
    redirectTo: '/recruiting',
    pathMatch: 'full',
  },
  {
    path: paths.einheit.name,
    children: [
      {
        path: paths.einheit.children.abteilungen.name,
        component: DepartmentsPageComponent,
      },
      // {
      //   path: paths.einheit.children.organigram.name,
      //   component: OrgDiagramPageComponent
      // }
    ]
  },
  {
    path: paths.medien,
    component: MediaPageComponent,
  },
  {
    path: 'statistics',
    component: StatisticsPageComponent,
    resolve: {
      ribbonsAndBadges: RibbonAndBadgeResolver,
    },
  },
  {
    path: 'statistics/ribbon/:id',
    component: RibbonPageComponent,
    resolve: {
      ribbonData: RibbonDataResolver,
    },
    data: { animation: 'ribbonPage' }
  },
  {
    path: 'statistics/management-badge/:name',
    component: ManagementBadgePageComponent,
    resolve: {
      managementBadgeData: ManagementBadgeDataResolver,
    },
  },
  {
    path: 'statistics/additional-badge/:name',
    component: AdditionalBadgePageComponent,
    resolve: {
      additionalBadgeData: AdditionalBadgeDataResolver,
    },
  },
  {
    path: '**',
    component: ErrorPageComponent,
  }
];
