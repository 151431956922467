import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {Meta, MetaDefinition} from '@angular/platform-browser';
import {HeadLink} from '../seo-service/config/head-link';
import {AdditionalHeadLinkAttributes} from '../seo-service/config/additional-head-link-attributes';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

@Injectable()
export class SeoBuilderService {

  protected metaTags: MetaDefinition[] = [];
  protected headLinks: HeadLink[] = [];

  protected renderer: Renderer2;

  constructor(
    protected metaService: Meta,
    protected rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  addMetaTag(name: string, content: string): this {

    const tag = {} as MetaDefinition;
    tag.name = name;
    tag.content = content;

    this.metaTags.push(tag);

    return this;
  }

  createLink(name: string, url: string, additionalAttributes?: AdditionalHeadLinkAttributes[]): this {

    const headLink = new HeadLink();
    headLink.name = name;
    headLink.url = url;
    headLink.additionalAttributes = additionalAttributes;

    this.headLinks.push(headLink);

    return this;
  }

  build() {

    this.metaService.addTags(this.metaTags);

    if (isPlatformBrowser(this.platformId)) {
      this.buildLinks();
    }
  }

  protected buildLinks() {

    for (const {name, url, additionalAttributes} of this.headLinks) {

      const link = this.renderer.createElement('link');
      link.setAttribute('rel', name);
      link.setAttribute('href', url);

      if (Array.isArray(additionalAttributes) && additionalAttributes.length > 0) {

        additionalAttributes.forEach((attribute) =>
          link.setAttribute(attribute.name, attribute.value)
        );
      }

      this.renderer.appendChild(this.document.head, link);
    }
  }
}
