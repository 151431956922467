import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import AdcmRepository from '../repositories/adcm/adcm.repository';
import {MemberData} from "../pages/profile-page/config/member-data";
import {RibbonStatistic} from "../types/ribbon-statistic";
import {ManagementBadgeStatistic} from "../types/management-badge-statistic";

@Injectable()
export class RibbonAndBadgeResolver {

  readonly #adcmRepo = inject(AdcmRepository);
  readonly #router = inject(Router);

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const allMembers = await lastValueFrom(this.#adcmRepo.getAllMemberInfo());

    return {
      ribbons: this.getRibbons(allMembers),
      managementBadges: this.getManagementBadges(allMembers),
      additionalBadges: this.getAdditionalBadges(allMembers),
      allMembers: allMembers,
    }
  }

  protected getRibbons(allMembers: MemberData[]): RibbonStatistic[] {

    const ribbons: RibbonStatistic[] = [];

    for (const member of allMembers) {

      for (const ribbon of member.ribbons) {

        const existingRibbon = ribbons.find(ribb => ribb.ribbon?.id === ribbon.id)
        if (!existingRibbon) {

          const newRibbon = {
            ribbon: ribbon,
            members: allMembers.filter(member => member.ribbons.find(rib => rib.id === ribbon.id)),
          }

          ribbons.push(newRibbon);
        }

      }
    }

    return ribbons;
  }

  protected getManagementBadges(allMembers: MemberData[]): ManagementBadgeStatistic[] {

    const managementBadges: ManagementBadgeStatistic[] = [];

    for (const member of allMembers) {

      for (const badge of member.managementBadges) {

        const existingBadge = managementBadges.find(bad => bad.badge.badge === badge.badge)
        if (!existingBadge) {

          const newBadge = {
            badge: badge,
            members: allMembers.filter(member => !member.archived &&  member.managementBadges.find(bad => bad.badge === badge.badge)),
          }

          managementBadges.push(newBadge);
        }

      }
    }

    return managementBadges;
  }

  protected getAdditionalBadges(allMembers: MemberData[]): ManagementBadgeStatistic[] {

    const additionalBadges: ManagementBadgeStatistic[] = [];

    for (const member of allMembers) {

      for (const badge of member.additionalBadges) {

        const existingBadge = additionalBadges.find(bad => bad.badge.badge === badge.badge)
        if (!existingBadge) {

          const newBadge = {
            badge: badge,
            members: allMembers.filter(member => !member.archived && member.additionalBadges.find(bad => bad.badge === badge.badge)),
          }

          additionalBadges.push(newBadge);
        }

      }
    }

    return additionalBadges;
  }
}
