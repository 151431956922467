import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import LineUpResponse from '../../types/lineup-response.type';
import {MemberData} from "../../pages/profile-page/config/member-data";

@Injectable()
export class StatisticsCacheService {

  private membersCache = new BehaviorSubject<MemberData[] | null>(null);
  public membersCache$ = this.membersCache.asObservable();

  private lineUpCache = new BehaviorSubject<LineUpResponse | null>(null);
  public lineUpCache$ = this.lineUpCache.asObservable();

  constructor() { }

  setMembersCache(members: MemberData[]) {
    this.membersCache.next(members);
  }

  setLineUpCache(lineUp: LineUpResponse) {
    this.lineUpCache.next(lineUp);
  }
}
