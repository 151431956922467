<footer [class.with-shadow]="true">
  <section>
    <div class="columns">
      <div class="column left">
        <app-theme-switcher></app-theme-switcher>
      </div>
      <div class="column right">
        <span *ngFor="let item of footerMenuConfig.items">
          <a *ngIf="!item.isExternal" [routerLink]="item.targetUrl" class="item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">
            <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
            {{ item.label }}
          </a>

          <a
            *ngIf="item.isExternal"
            [href]="item.targetUrl"
            target="_blank"
            rel="noreferrer"
            class="item"
          >
            <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
            {{ item.label }}
          </a>
        </span>
      </div>
    </div>
  </section>
</footer>
