import { Component, OnInit, inject } from '@angular/core';
import paths from 'src/paths';
import {faAngleDown, faBars} from '@fortawesome/free-solid-svg-icons';
import {HeaderMenuBuilder} from './builder/header-menu-builder';
import {HeaderMenu} from './config/header-menu';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {BehaviorSubject} from "rxjs";
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { DesktopMenuComponent } from './desktop-menu/desktop-menu.component';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'air-navbar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, RouterLink, NgOptimizedImage, DesktopMenuComponent, MobileMenuComponent]
})
export class NavBarComponent implements OnInit {

  readonly #headerMenuBuilder = inject(HeaderMenuBuilder);

  headerMenuConfig: HeaderMenu;
  sidebarVisible = new BehaviorSubject(false);

  faBars = faBars as IconDefinition;

  ngOnInit(): void {
    this.buildMenuItems();
  }

  setSidebarVisibleState(state: boolean) {
    this.sidebarVisible.next(state);
  }

  protected buildMenuItems() {

    this.#headerMenuBuilder
      .clear()
      .addIcon('./assets/icons/US_101st_Airborne_Division_patch-1.svg', '101st Airborne Division')
      .addItem('Aufstellung', '/lineup')
      .addItemWithSubmenu('Ausbildung', faAngleDown as IconDefinition, faAngleDown as IconDefinition)
        .addItemToSubmenu('Mentorensystem', '/training/mentorsystem')
        .addItemToSubmenu('Trainingsmodule', '/training/modules')
        .addItemToSubmenu('Kommunikationsstruktur', paths.ausbildung.children.communications.full)
        .addItemToSubmenu('Feldhandbuch', 'https://wiki.airborne-division.de/index.php/Training', true)
      .addItem('Rekrutierung', '/recruiting')
      .addItem('Organisation', paths.einheit.children.abteilungen.full)
      .addItem('Forum', 'https://airborne-division.de/community/forum/', true)
      .addItem('Discord', '/discord')
      .addItem('Events', 'https://airborne-division.de/community/calendar/', true)
      .addItem('Medien', '/media');

    this.headerMenuConfig = this.#headerMenuBuilder.buildConfig();
  }
}
