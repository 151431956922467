import { Component, OnInit } from '@angular/core';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {SEOService} from "../../service/seo-service/seo.service";

import {ImageModule} from "primeng/image";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@Component({
  standalone: true,
  imports: [
    ImageModule,
    MainLayoutComponent,
    AirCardComponent,
    FontAwesomeModule
  ],
  selector: 'app-communication-page',
  templateUrl: './communication-page.component.html',
  styleUrls: ['./communication-page.component.scss']
})
export class CommunicationPageComponent implements OnInit {

  faSearchPlus = faSearchPlus as IconDefinition;

  constructor(
    protected seoService: SEOService,
  ) { }

  ngOnInit(): void {

    this.seoService.setPageTitle('Kommunikation');
    this.seoService.updateKeyWords(['kommunikation', 'communications'])
  }

}
