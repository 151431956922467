<p
  [ngClass]="{
    'text-center': true,
    'air-lineup-title': !isAlternateHeader,
    'air-lineup-sub-title': isAlternateHeader,
    'text-xl': !isAlternateHeader,
    'text-lg': isAlternateHeader
  }"
>
  <ng-content></ng-content>
</p>
