<air-main-layout>
  <div class="grid w-full">
    <div class="col">
      <air-card>
        <p-image src="./assets/images/kommunikationsstruktur2023.png" [preview]="true" alt="Kommunikationsstruktur" width="100%">
          <ng-template pTemplate="indicator">
            <fa-icon [icon]="faSearchPlus"></fa-icon>
          </ng-template>
        </p-image>
      </air-card>
    </div>
  </div>
</air-main-layout>
