import {Component, OnInit} from '@angular/core';
import {ThemeService} from '../../service/theme-service/theme.service';
import {DomSanitizer} from '@angular/platform-browser';
import {SEOService} from '../../service/seo-service/seo.service';

import {NgOptimizedImage} from "@angular/common";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {AirButtonComponent} from "../../shared/air-button/air-button.component";
import {ExternalContentComponent} from "../../shared/external-content/external-content.component";

@Component({
  standalone: true,
  imports: [
    NgOptimizedImage,
    MainLayoutComponent,
    AirCardComponent,
    AirButtonComponent,
    ExternalContentComponent
  ],
  selector: 'air-discord-page',
  templateUrl: './discord-page.component.html',
  styleUrls: ['./discord-page.component.scss']
})
export class DiscordPageComponent implements OnInit {

  constructor(
    protected themeService: ThemeService,
    protected sanitizer: DomSanitizer,
    protected seoService: SEOService,
  ) { }

  ngOnInit() {

    this.seoService.setPageTitle('Discord');
    this.seoService.updateKeyWords(['discord'])
  }

  getDiscordWidgetSource() {
    const discordWidgetUrl = 'https://discord.com/widget?id=310491308038815744&theme=' + this.themeService.getCurrentThemeNameShort();
    return this.sanitizer.bypassSecurityTrustResourceUrl(discordWidgetUrl)
  }

}
