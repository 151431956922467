import { APP_ID, ApplicationConfig } from "@angular/core";
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import { HeaderMenuBuilder } from "./modules/menu/navbar/builder/header-menu-builder";
import { FooterMenuBuilder } from "./modules/menu/footer/builder/footer-menu-builder.service";
import { MentorlistRepository } from "./modules/repositories/mentorlist/mentorlist.repository";
import AdcmRepository from "./modules/repositories/adcm/adcm.repository";
import { WikiRepository } from "./modules/repositories/wiki/wiki.repository";
import { ScreenshotsRepository } from "./modules/repositories/screenshots/screenshots.repository";
import { SEOService } from "./modules/service/seo-service/seo.service";
import { LocalstorageService } from "./modules/service/localstorage-service/localstorage.service";
import { ExternalContentService } from "./modules/service/external-content-service/external-content.service";
import { UrlService } from "./modules/service/url-service/url.service";
import { LineupCacheService } from "./modules/service/lineup-cache-service/lineup-cache.service";
import { SeoBuilderService } from "./modules/service/seo-builder/seo-builder.service";
import { StatisticsCacheService } from "./modules/service/statistics-cache-service/statistics-cache.service";
import { AirborneTranslationService } from "./modules/service/airborne-translation-service/airborne-translation.service";
import { ThemeService } from "./modules/service/theme-service/theme.service";
import { MemberDataResolver } from "./modules/resolver/member-data.resolver";
import { RibbonDataResolver } from "./modules/resolver/ribbon-data-resolver.service";
import { ManagementBadgeDataResolver } from "./modules/resolver/management-badge-data-resolver.service";
import { AdditionalBadgeDataResolver } from "./modules/resolver/additional-badge-data-resolver.service";
import { RibbonAndBadgeResolver } from "./modules/resolver/ribbon-and-badge-resolver.service";
import { provideRouter } from "@angular/router";
import { routes } from "src/routes";

export const appConfig: ApplicationConfig = {
  providers: [
        { provide: APP_ID, useValue: 'serverApp'},
        provideRouter(routes),
        // importProvidersFrom(),
        provideClientHydration(),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),

        // Airborne Tools
        HeaderMenuBuilder,
        FooterMenuBuilder,
        MentorlistRepository,
        AdcmRepository,
        WikiRepository,
        ScreenshotsRepository,
        SEOService,
        ThemeService,
        LocalstorageService,
        ExternalContentService,
        UrlService,
        LineupCacheService,
        SeoBuilderService,
        StatisticsCacheService,
        AirborneTranslationService,
        MemberDataResolver,
        RibbonDataResolver,
        ManagementBadgeDataResolver,
        AdditionalBadgeDataResolver,
        RibbonAndBadgeResolver,
  ]
}
