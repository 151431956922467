import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import AdcmRepository from '../repositories/adcm/adcm.repository';

@Injectable()
export class MemberDataResolver {

  readonly #adcmRepo = inject(AdcmRepository);
  readonly #router = inject(Router);

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const memberInfo = await lastValueFrom(this.#adcmRepo.getAllMemberInfo())
    const currentMemberName = route.paramMap.get('name');

    const memberData = memberInfo.find(member => member.name.toLowerCase() === currentMemberName?.toLowerCase())

    if (!memberData) {

      console.warn('No matching member found. Routing to recruiting.')

      this.#router.navigate(['/recruiting']);
    }

    return memberData;
  }

}
