<air-main-layout>
  <div class="grid w-full">
    <div class="col p-0">
      <air-card>
        <h1
          *ngIf="caption && isMainCaption"
          class="text-center air-card-title mb-3"
        >
          {{ caption }}
        </h1>
        <h2 *ngIf="caption && !isMainCaption" class="air-card-title mb-2">
          {{ caption }}
        </h2>
        <p-table
          #dt
          styleClass="p-datatable-sm"
          *ngIf="values"
          [value]="values"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          responsiveLayout="scroll"
          currentPageReportTemplate="{first} bis {last} werden angezeigt. Insgesamt {totalRecords} Einträge."
          [rowsPerPageOptions]="[10, 25, 50]"
          [scrollable]="scrollable"
          [scrollHeight]="scrollHeight"
          [globalFilterFields]="['content']"
        >
          <ng-template pTemplate="caption" *ngIf="globalFilterActive">
            <div class="p-d-flex text-right">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Suche ..." />
              </span>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th
                [ngStyle]="head.styles || null"
                [ngClass]="head.classes || ''"
                *ngFor="let head of tableHeadInfo"
              >
                {{ head.label }}
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-values>
            <tr>
              <td
                *ngFor="let content of values.content"
                [innerHTML]="content"
              ></td>
            </tr>
          </ng-template>
        </p-table>

        <ng-container *ngIf="!values">
          <h3 class="text-center">Keine Daten verfügbar</h3>
        </ng-container>
      </air-card>
    </div>
  </div>
</air-main-layout>
