import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ExternalContentService } from '../../service/external-content-service/external-content.service';
import paths from 'src/paths';
import { DOCUMENT, isPlatformBrowser, NgIf, NgClass } from '@angular/common';
import { AirButtonComponent } from '../air-button/air-button.component';
import { RouterLink } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';

@Component({
    selector: 'air-external-content',
    templateUrl: './external-content.component.html',
    styleUrls: ['./external-content.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, AccordionModule, RouterLink, AirButtonComponent]
})
export class ExternalContentComponent implements OnInit {
  @Input() name: string;
  @Input() jsLibs: string[] | undefined;
  @Input() noPadding: boolean = false;

  externalContent: ExternalContentService;

  constructor(
    protected externalContentService: ExternalContentService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.externalContent = externalContentService;
  }

  ngOnInit(): void {
    if (
      this.externalContent.isAllExternalContentAllowed() ||
      this.externalContent.checkIsContentAllowed(this.name)
    ) {
      this.loadJsScripts();
    }
  }

  get paths() {
    return paths;
  }

  onAllowContentClick() {
    this.externalContent.allowExternalContent(this.name);
    this.loadJsScripts();
  }

  private loadJsScripts() {
    if (isPlatformBrowser(this.platformId) && this.jsLibs) {
      this.jsLibs.forEach((elem) => {
        const node = this.document.createElement('script');
        node.src = elem;
        node.type = 'text/javascript';
        node.async = true;
        this.document.getElementsByTagName('body')[0].appendChild(node);
      });
    }
  }
}
