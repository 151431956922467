import { Component, OnInit } from '@angular/core';
import { faSadCry } from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";


@Component({
  standalone: true,
  imports: [
    MainLayoutComponent,
    AirCardComponent,
    FontAwesomeModule
  ],
  selector: 'air-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  faSadCry = faSadCry as IconDefinition;

  constructor() {}

  ngOnInit(): void {}
}
