<air-main-layout>
  <div class="grid w-full">
    <div class="col">
      <air-card>
        <h1 class="text-center air-card-title mb-0">Das Training</h1>
        <div class="grid mt-2">
          <div class="col-12 md:col-6 pb-0 air-dual-column-left">
            <p class="text-justify">
              Nachdem dein Mentor dich in die absoluten Grundzüge unserer
              Lerninhalte eingewiesen hat, wird deine Ausbildung in fünf
              weiteren Modulen vertieft. Das Training findet wöchentlich statt
              und sobald ein Trainingszyklus beendet wurde beginnt ein neuer.
              Für dich als Rekrut ist es jedoch unerheblich, zu welchem
              Zeitpunkt du in den Zyklus einsteigst, da die Module inhaltlich
              abgeschlossen sind.
            </p>

            <p class="text-justify">
              Nachdem du jedes Modul einmal besucht hast, wirst du zu einer
              abschließenden Prüfung zugelassen, die das Ende deiner
              Grundausbildung darstellt.
            </p>
          </div>
          <div class="col-12 md:col-6 pb-0 air-dual-column-right">
            <img
              class="air-image"
              ngSrc="../../../../assets/images/Main_composition_00709_1.jpg"
              alt="Eine Schulung während des Trainings"
              height="478"
              width="850"
            />
          </div>
        </div>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col">
      <air-card headerTitle="Teilnahme an Missionen">
        <p class="text-justify mb-0">
          Du kannst an Missionen teilnehmen, ohne vorher ein Training gemacht zu
          haben. Ein Mentorentraining ist jedoch vorher Pflicht.
        </p>
        <span class="flex justify-content-center mt-2">
          <air-button [link]="paths.ausbildung.children.mentorensystem.full">
            Erfahre mehr - Mentorentraining
          </air-button>
        </span>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col">
      <air-card headerTitle="Module">
        <p-panel
          class="air-panel"
          header="Modul 1 - Struktur und Funk"
          [toggleable]="true"
          toggler="header"
          [collapsed]="true"
        >
          <div class="grid">
            <div class="col-12 md:col-6 air-dual-column-left pt-0 pl-0 pb-0">
              <p class="text-justify">
                Inhalte des Modul 1 sind die Grundlagen des Formaldienstes, eine
                Einführung in militärische Strukturen sowie die Organisation und
                Aufgabenverteilung innerhalb des US. Army Rifle Platoons. Des
                Weiteren wird das Themengebiet “Funk” und “Rufzeichen”
                behandelt. Damit ist Modul 1 mit Ausnahme des Formaldienstes ein
                reines Theoriemodul.
              </p>

              <h2 class="air-card-title">Inhalte</h2>
              <ul class="air-list mt-2">
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Ränge
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Militärische Organisation
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Das Rifleplatoon
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Formaldienst
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Funkformalia
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Funkdisziplin
                </li>
              </ul>
            </div>
            <div class="col-12 md:col-6 air-dual-column-right pt-0 pr-0 pb-0">
              <img
                class="air-image"
                ngSrc="../../../../assets/images/Main_composition_01077.jpg"
                alt="Aufstellung während des Trainings"
                height="478"
                width="850"
              />
            </div>
          </div>
          <span class="flex justify-content-center mt-2">
            <air-button
              link="https://wiki.airborne-division.de/index.php/Modul_1_-_Struktur_und_Funk"
            >
              Erfahre mehr - Modul 1
            </air-button>
          </span>
        </p-panel>

        <p-panel
          class="air-panel"
          header="Modul 2 - Formation und Drills"
          [toggleable]="true"
          toggler="header"
          [collapsed]="true"
        >
          <div class="grid">
            <div class="col-12 md:col-6 air-dual-column-left pt-0 pl-0 pb-0">
              <h2 class="air-card-title">Feldhandbuch - Modul 2</h2>
              <p class="text-justify">
                Formationen helfen dem Squad oder dem Platoon, ständige
                Rundumsicherung zu wahren, ermöglichen schnelles und sicheres
                Vorrücken und sind wichtiger Teil der Ausbildung.
              </p>
              <h2 class="air-card-title">Inhalte</h2>
              <ul class="air-list mt-2">
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Formationen
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Basic Battle Drills
                </li>
              </ul>
            </div>
            <div class="col-12 md:col-6 air-dual-column-right pt-0 pr-0 pb-0">
              <img
                class="air-image"
                ngSrc="../../../../assets/images/Main_composition_01198.jpg"
                alt="Ausspähen des Feldes"
                height="478"
                width="850"
              />
            </div>
          </div>
          <span class="flex justify-content-center mt-2">
            <air-button
              link="https://wiki.airborne-division.de/index.php/Modul_2_-_Formationen_und_Drills"
            >
              Erfahre mehr - Modul 2
            </air-button>
          </span>
        </p-panel>

        <p-panel
          class="air-panel"
          header="Modul 3 - Karte & Kompass"
          [toggleable]="true"
          toggler="header"
          [collapsed]="true"
        >
          <div class="grid">
            <div class="col-12 md:col-6 air-dual-column-left pt-0 pl-0 pb-0">
              <h2 class="air-card-title">Feldhandbuch - Modul 3</h2>
              <ul class="air-list mt-2">
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Koordinaten
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Legende
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Maßstab
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Natosymbole
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Orientierungsmarsch
                </li>
              </ul>
            </div>
            <div class="col-12 md:col-6 air-dual-column-right pt-0 pr-0 pb-0">
              <img
                class="air-image"
                ngSrc="../../../../assets/images/Main_composition_00483.jpg"
                alt="Karte und Kompass"
                height="478"
                width="850"
              />
            </div>
          </div>
          <span class="flex justify-content-center mt-2">
            <air-button
              link="https://wiki.airborne-division.de/index.php/Modul_3_-_Karte_%26_Kompass"
            >
              Erfahre mehr - Modul 3
            </air-button>
          </span>
        </p-panel>

        <p-panel
          class="air-panel"
          header="Modul 4 - Standardwaffensyteme & Erste Hilfe"
          [toggleable]="true"
          [collapsed]="true"
          toggler="header"
        >
          <div class="grid">
            <div class="col-12 md:col-6 air-dual-column-left pt-0 pl-0 pb-0">
              <h2 class="air-card-title">Feldhandbuch - Modul 4</h2>
              <p class="text-justify">
                Inhalte des Moduls sind die Standardwaffensysteme, die in
                unserer Einheit verwendet werden. Des Weiteren wird der Umgang
                mit unserem Standardvisier erläutert.
              </p>
              <h2 class="air-card-title">Inhalte</h2>
              <ul class="air-list mt-2">
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  AT4
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  M249
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  M4
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  M9
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Handgranate
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Sprengladungen
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Erste-Hilfe
                </li>
              </ul>
            </div>
            <div class="col-12 md:col-6 air-dual-column-right pt-0 pr-0 pb-0">
              <img
                class="air-image"
                ngSrc="../../../../assets/images/Main_composition_00947.jpg"
                alt="Behandlung eines Verletzten "
                height="478"
                width="850"
              />
            </div>
          </div>
          <span class="flex justify-content-center mt-2">
            <air-button
              link="https://wiki.airborne-division.de/index.php/Modul_4_-_Standardwaffensysteme_%26_Erste_Hilfe"
            >
              Erfahre mehr - Modul 4
            </air-button>
          </span>
        </p-panel>

        <p-panel
          header="Modul 5 - Häuserkampf"
          [toggleable]="true"
          [collapsed]="true"
          toggler="header"
        >
          <div class="grid">
            <div class="col-12 md:col-6 air-dual-column-left pt-0 pl-0 pb-0">
              <h2 class="air-card-title">Feldhandbuch - Modul 5</h2>
              <ul class="air-list mt-2">
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Häuserkampf Theorie
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Standardformationen
                </li>
                <li>
                  <fa-icon class="mr-1" [icon]="faCheck"></fa-icon>
                  Eindringen in Gebäude
                </li>
              </ul>
            </div>
            <div class="col-12 md:col-6 air-dual-column-right pt-0 pr-0 pb-0">
              <img
                class="air-image"
                ngSrc="../../../../assets/images/107410_screenshots_20171103233610_1.jpg"
                alt="Häuserkampf"
                height="478"
                width="850"
              />
            </div>
          </div>
          <span class="flex justify-content-center mt-2">
            <air-button
              link="https://wiki.airborne-division.de/index.php/Modul_5_-_H%C3%A4userkampf"
            >
              Erfahre mehr - Modul 5
            </air-button>
          </span>
        </p-panel>
      </air-card>
    </div>
  </div>
</air-main-layout>
