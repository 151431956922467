import {Injectable} from '@angular/core';
import {FooterMenuItem} from '../config/footer-menu-item';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FooterMenu} from '../config/footer-menu';

@Injectable()
export class FooterMenuBuilder {

  protected items: FooterMenuItem[] = [];

  addItem(label: string, targetUrl: string, icon?: IconDefinition, isExternal?: boolean): this {

    const item = new FooterMenuItem();
    item.label = label;
    item.targetUrl = targetUrl;
    item.icon = icon;
    item.isExternal = isExternal;

    this.items.push(item);

    return this;
  }

  clear(): this {

    this.items = [];

    return this;
  }

  buildConfig(): FooterMenu {

    const config = new FooterMenu()
    config.items = this.items;

    return config;
  }
}
