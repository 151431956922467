<air-loading [isLoading]="isLoading() && !hasError()"></air-loading>
<div *ngIf="hasError()" style="text-align: center">Fehler beim Laden der Daten</div>

<air-main-layout>
  <div class="grid w-full">
    <div class="col" *ngIf="!isLoading() && !hasError()">
      <air-data-table
        [tableHeadInfo]="header"
        [values]="mentorList"
        caption="Schulungen"
        [isMainCaption]="true"
        [globalFilterActive]="true"
      ></air-data-table>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col" *ngIf="!isLoading()">
      <air-data-table
        [tableHeadInfo]="headerMentorLeaderBoardList"
        [values]="mentorLeaderboard"
        caption="Leaderboard"
        [globalFilterActive]="true"
      ></air-data-table>
    </div>
  </div>

</air-main-layout>
