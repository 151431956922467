import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import paths from 'src/paths';
import {AirButtonComponent, ButtonSize} from '../../shared/air-button/air-button.component';
import {faTwitch, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {faSearchPlus} from '@fortawesome/free-solid-svg-icons';
import {SEOService} from '../../service/seo-service/seo.service';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {ScreenshotsRepository} from "../../repositories/screenshots/screenshots.repository";
import {async, catchError, of, tap} from "rxjs";

import {AsyncPipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {DividerModule} from "primeng/divider";
import {ImageModule} from "primeng/image";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {LoadingComponent} from "../../shared/loading/loading.component";


@Component({
  standalone: true,
  imports: [
    NgOptimizedImage,
    DividerModule,
    NgIf,
    NgForOf,
    ImageModule,
    AsyncPipe,
    MainLayoutComponent,
    AirCardComponent,
    AirButtonComponent,
    FontAwesomeModule,
    LoadingComponent,
  ],
  selector: 'air-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPageComponent implements OnInit {

  readonly #seoService = inject(SEOService);
  readonly #screenShotRepo = inject(ScreenshotsRepository);

  hasScreenshots = false;
  isLoading = true;
  galleryImages$ = this.#screenShotRepo.getScreenshots()
    .pipe(
      catchError(() => of([])),
      tap(screenshots => this.hasScreenshots = screenshots.length > 0),
      tap(() => this.isLoading = false)
    );

  faTwitch = faTwitch as IconDefinition;
  faYoutube = faYoutube as IconDefinition;
  faTwitter = faTwitter as IconDefinition;
  faSearchPlus = faSearchPlus as IconDefinition;
  ButtonSize = ButtonSize;
  paths = paths;

  ngOnInit(): void {

    this.#seoService.setPageTitle('Medien');
    this.#seoService.updateKeyWords(['medien', 'media']);
  }
}
