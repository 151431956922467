<ng-container
  *ngIf="
    externalContent.isAllExternalContentAllowed() ||
    externalContent.checkIsContentAllowed(name)
  "
>
  <div [ngClass]="{ 'card-padding': !noPadding, 'card-no-padding': noPadding }">
    <ng-content></ng-content>
  </div>
</ng-container>

<ng-container *ngIf="!externalContent.checkIsContentAllowed(name)">
  <div class="air-external-content-consent">
    <div class="text flex justify-content-center align-items-start flex-column">
      <h3 class="mb-2 mt-1">Inhalt von {{ name }}</h3>

      <p-accordion class="mb-3">
        <p-accordionTab header="Was hat es damit auf sich?">
          <p>
            Das Nachladen von externen Inhalten ist zum Schutz der Privatsphäre
            deaktiviert.
          </p>
          <p>
            Wenn du Inhalt von {{ name }} erlaubst, können Cookies der externen
            Seite zur Identifizierung gespeichert werden.
          </p>
          <p>
            Mehr Informationen zu diesem Thema und wie du die Genehmigung widerrufen kannst, findest du unter
            <a class="alternate-text-link" [routerLink]="['/', paths.datenschutz]">Datenschutz</a>.
          </p>
        </p-accordionTab>
      </p-accordion>

      <air-button [noActions]="true" (click)="onAllowContentClick()">
        {{ name }} erlauben
      </air-button>
    </div>
  </div>
</ng-container>
