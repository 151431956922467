import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ScreenshotsRepository {
  constructor(protected http: HttpClient) {}

  protected getModelName(): string {
    return `/api/screenshots`;
  }
  getScreenshots(): Observable<string[]> {
    return this.http.get<string[]>(
      this.getModelName()
    );
  }
}
