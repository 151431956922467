import { Component, OnInit } from '@angular/core';
import paths from 'src/paths';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {SEOService} from '../../service/seo-service/seo.service';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

import {NgOptimizedImage} from "@angular/common";
import {PanelModule} from "primeng/panel";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {AirButtonComponent} from "../../shared/air-button/air-button.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@Component({
  standalone: true,
  imports: [
    NgOptimizedImage,
    PanelModule,
    MainLayoutComponent,
    AirCardComponent,
    AirButtonComponent,
    FontAwesomeModule
  ],
  selector: 'air-modules-page',
  templateUrl: './modules-page.component.html',
  styleUrls: ['./modules-page.component.scss']
})
export class ModulesPageComponent implements OnInit {
  lastToggled: number = 0;
  faCheck = faCheck as IconDefinition;

  afterCollapse(toggleState: boolean, index: number) {
    if (toggleState) {
      this.lastToggled = 0;
    } else {
      this.lastToggled = index;
    }
  }

  get paths() {
    return paths;
  }

  constructor(protected seoService: SEOService) {}

  ngOnInit(): void {

    this.seoService.setPageTitle('Module');
    this.seoService.updateKeyWords(['module', 'training'])
  }
}
