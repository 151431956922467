import { Component, Input, OnInit } from '@angular/core';
import LineUpGroup from '../../types/lineup-group.type';

import {LineUpItemParagraphComponent} from "../line-up-item-paragraph/line-up-item-paragraph.component";
import {NgForOf, NgIf} from "@angular/common";
import {LineUpCardHeaderComponent} from "../line-up-card-header/line-up-card-header.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";

@Component({
  standalone: true,
  imports: [
    LineUpItemParagraphComponent,
    NgIf,
    NgForOf,
    LineUpCardHeaderComponent,
    AirCardComponent
  ],
  selector: 'air-line-up-squad-card',
  templateUrl: './line-up-squad-card.component.html',
  styleUrls: ['./line-up-squad-card.component.scss']
})
export class LineUpSquadCardComponent implements OnInit {
  @Input() isAlternateCard: boolean = false;
  @Input() title: string;
  @Input() squadData: LineUpGroup | undefined;
  @Input() id: number | undefined;

  constructor() {}

  ngOnInit(): void {}
}
