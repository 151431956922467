<p-sidebar (onHide)='switchScrollLock(false)' (onShow)='switchScrollLock(true)' [(visible)]="isSidebarOpen" [baseZIndex]="10000" styleClass='side-menu' class='side-menu' [style]="{backgroundColor: '#303030', width: 'unset'}">
  <ul class='side-menu-nav'>

    <nav>
      <li *ngFor='let item of config.items' class='nav-item'>
        <a *ngIf='!item.subMenu && item.isExternal' [href]='item.targetUrl' class='item' target='_blank' (click)='closeSidebar()'>{{item.label}}</a>
        <a *ngIf='!item.subMenu && !item.isExternal' [routerLink]='item.targetUrl' class='item' (click)='closeSidebar()'>{{item.label}}</a>

        <a *ngIf='item.subMenu' class='item' >{{item.label}}</a>
        <ul *ngIf='item.subMenu'>
          <li *ngFor='let subMenuItem of item.subMenu' class='nav-item'>
            <a *ngIf='subMenuItem.isExternal' [href]='subMenuItem.targetUrl' class='item' target='_blank' (click)='closeSidebar()'>{{subMenuItem.label}}</a>
            <a *ngIf='!subMenuItem.isExternal' [routerLink]='subMenuItem.targetUrl' class='item' (click)='closeSidebar()'>{{subMenuItem.label}}</a>
          </li>
        </ul>
      </li>
    </nav>

  </ul>
</p-sidebar>
