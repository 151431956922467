import { Component, OnInit } from '@angular/core';
import paths from 'src/paths';
import {SEOService} from '../../service/seo-service/seo.service';

import {NgOptimizedImage} from "@angular/common";
import {TableModule} from "primeng/table";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {AirButtonComponent} from "../../shared/air-button/air-button.component";

@Component({
  standalone: true,
  imports: [
    NgOptimizedImage,
    TableModule,
    MainLayoutComponent,
    AirCardComponent,
    AirButtonComponent
  ],
  selector: 'air-mentorsystem-page',
  templateUrl: './mentorsystem-page.component.html',
  styleUrls: ['./mentorsystem-page.component.scss']
})
export class MentorsystemPageComponent implements OnInit {
  get items() {
    return [
      {
        title: 'ArmaSync',
        text: 'Einrichten des Tools “ArmaSync” zum Herunterladen der Mods und zum Verbinden auf den Spielserver'
      },
      {
        title: 'Verbinden zum Server',
        text: 'Verbindung zum Server aufbauen und testen'
      },
      {
        title: 'Antreten',
        text: 'Das Antreten der Mannschaft vor dem Vorgesetzten'
      },
      {
        title: 'Ränge der US. Army',
        text: 'Die für dich relevanten Ränge bei uns im Clan'
      },
      {
        title: 'Squad-Aufteilung und “Wer gehört zu wem?”',
        text: 'Wie ist ein Squad strukturiert und welche Positionen gibt es'
      },
      {
        title: 'Do as I do',
        text: 'Das Führungsprinzip deines Vorgesetzten'
      },
      {
        title: 'Formationen: Wedge und File',
        text: 'Einführung in die Standardformationen'
      },
      {
        title: 'Sicherungsbereiche',
        text: 'Sicherungsbereiche in den verschiedenen Formationen'
      },
      {
        title: 'Bedienung Funkgerät',
        text: 'Öffnen und Einstellen des Funkgeräts'
      },
      {
        title: 'Übliche Frequenzen des Funkgeräts',
        text: 'Welche Funkfrequenzen gibt es bei uns und wofür werden diese benutzt'
      },
      {
        title: 'Nato-Alphabet',
        text: 'Das Nato-Alphabet. Lerne es, liebe es!'
      },
      {
        title: 'Feindmeldungen',
        text: 'Wie erkenne ich den Feind und wie melde ich diesen'
      },
      {
        title: 'Aufruf Medic-System',
        text: 'Wie wird das Sanitätsmodul aufgerufen'
      },
      {
        title: 'Tipps für den Soldaten von heute',
        text: 'Allgemeine Tipps, die dir deinen Alltag erleichtern'
      }
    ];
  }

  get paths() {
    return paths;
  }

  constructor(protected seoService: SEOService) {}

  ngOnInit(): void {

    this.seoService.setPageTitle('Mentorensystem');
    this.seoService.updateKeyWords(['mentorensystem', 'mentorsystem'])
  }
}
