<ng-container>
  <div class="theme-list cursor-pointer" *ngIf="!asColorDots">
    <span
      class="theme-entry"
      *ngFor="let theme of availableThemes"
      (click)="setTheme(theme.name)"
      [class.active]="theme.name === activeTheme"
    >
      <fa-icon *ngIf="theme.icon" class="align-center" [icon]="theme.icon" ></fa-icon>
    </span>
  </div>

  <div class="theme-list color-dots cursor-pointer" *ngIf="asColorDots" (click)="selectNextTheme()">
    <div class="theme-color color-1"></div>
    <div class="theme-color color-2"></div>
    <div class="theme-color color-3"></div>
    <div class="theme-color color-4"></div>
  </div>
</ng-container>
