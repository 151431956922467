<air-main-layout>
  <div class="grid w-full">
    <div class="col-12">
      <air-card>
        <h1 class="text-center air-card-title mb-0">Datenschutzerklärung</h1>
        <h2 class="air-card-sub-title">Grundlegendes</h2>
        <p class="text-justify">
          Diese Datenschutzerklärung soll die Nutzer dieser Website über die
          Art, den Umfang und den Zweck der Erhebung und Verwendung
          personenbezogener Daten durch den Websitebetreiber informieren.
        </p>
        <p class="text-justify">
          Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt
          Ihre personenbezogenen Daten vertraulich und entsprechend der
          gesetzlichen Vorschriften. Da durch neue Technologien und die ständige
          Weiterentwicklung dieser Webseite Änderungen an dieser
          Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen
          sich die Datenschutzerklärung in regelmäßigen Abständen wieder
          durchzulesen. Definitionen der verwendeten Begriffe (z.B.
          “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4
          DSGVO.
        </p>

        <h2 class="air-card-sub-title">Zugriffsdaten</h2>
        <p class="text-justify mb-1">
          Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund
          unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten
          über Zugriffe auf die Website und speichern diese als
          „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten werden
          so protokolliert:
        </p>
        <ul class="air-list mt-2 mb-2">
          <li>
            <fa-icon class="mr-1" [icon]="faChevronRight"></fa-icon> Besuchte
            Website
          </li>
          <li>
            <fa-icon class="mr-1" [icon]="faChevronRight"></fa-icon> Uhrzeit zum
            Zeitpunkt des Zugriffes
          </li>
          <li>
            <fa-icon class="mr-1" [icon]="faChevronRight"></fa-icon> Verwendeter
            Browser
          </li>
          <li>
            <fa-icon class="mr-1" [icon]="faChevronRight"></fa-icon> Verwendetes
            Betriebssystem
          </li>
          <li>
            <fa-icon class="mr-1" [icon]="faChevronRight"></fa-icon> Verwendete
            IP-Adresse
          </li>
        </ul>
        <p class="text-justify">
          Die Server-Logfiles werden für maximal 7 Tage gespeichert und
          anschließend gelöscht. Die Speicherung der Daten erfolgt aus
          Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können.
          Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von
          der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.
        </p>

        <h2 class="air-card-sub-title">Cookies</h2>
        <p class="text-justify">
          Diese Website verwendet Cookies, die entweder von unserem Server oder
          dem Server Dritter an den Browser des Nutzers übertragen werden. Bei
          Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endgerät
          gespeichert werden. Ihr Browser greift auf diese Dateien zu. Durch den
          Einsatz von Cookies erhöht sich die Benutzerfreundlichkeit und
          Sicherheit dieser Website.
        </p>
        <p class="text-justify">
          Gängige Browser bieten die Einstellungsoption, Cookies nicht
          zuzulassen. Hinweis: Es ist nicht gewährleistet, dass Sie auf alle
          Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn
          Sie entsprechende Einstellungen vornehmen.
        </p>

        <h2 class="air-card-sub-title">
          Erfassung und Verarbeitung personenbezogener Daten
        </h2>
        <p class="text-justify">
          Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen
          Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist
          oder Sie in die Datenerhebung einwilligen. Als personenbezogene Daten
          gelten sämtliche Informationen, welche dazu dienen, Ihre Person zu
          bestimmen und welche zu Ihnen zurückverfolgt werden können – also
          beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer. Diese
          Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu
          machen. Zur Verbesserung unseres Online-Angebotes speichern wir jedoch
          (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen
          Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei oder der
          Name Ihres Internet-Providers. Durch die Anonymisierung der Daten sind
          Rückschlüsse auf Ihre Person nicht möglich.
        </p>

        <h2 class="air-card-sub-title">SSL- bzw. TLS-Verschlüsselung</h2>
        <p class="text-justify">
          Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
          Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
          Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
          über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
          erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
          Ihres Browsers und am Schloss-Symbol in der Browserzeile.
        </p>

        <h2 class="air-card-sub-title">YouTube</h2>
        <p class="text-justify">
          Für Integration und Darstellung von Videoinhalten nutzt unsere Website
          Plugins von YouTube. Anbieter des Videoportals ist die YouTube, LLC,
          901 Cherry Ave., San Bruno, CA 94066, USA.
        </p>
        <p class="text-justify">
          Bei Aufruf einer Seite mit integriertem YouTube-Plugin wird eine
          Verbindung zu den Servern von YouTube hergestellt. YouTube erfährt
          hierdurch, welche unserer Seiten Sie aufgerufen haben.
        </p>
        <p class="text-justify">
          YouTube kann Ihr Surfverhalten direkt Ihrem persönlichen Profil
          zuzuordnen, sollten Sie in Ihrem YouTube Konto eingeloggt sein. Durch
          vorheriges Ausloggen haben Sie die Möglichkeit, dies zu unterbinden.
        </p>
        <p class="text-justify">
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        </p>
        <p class="text-justify">
          Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von YouTube unter:
          <a
            href="https://www.google.de/intl/de/policies/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://www.google.de/intl/de/policies/privacy
          </a>
          .
        </p>

        <h2 class="air-card-sub-title">Forum</h2>
        <p class="text-justify">
          Sie können sich auf unserer Website (Forum) registrieren, um
          zusätzliche Funktionen auf der Seite zu nutzen. Die dazu eingegebenen
          Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen
          Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei
          der Registrierung abgefragten Pflichtangaben müssen vollständig
          angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.
          Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
          notwendigen Änderungen nutzen wir die bei der Registrierung angegebene
          E-Mail-Adresse, um Sie auf diesem Wege zu informieren. Die
          Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf
          Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können
          eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht
          eine formlose Mitteilung per E-Mail an uns. Kontaktmöglichkeiten sind
          im Impressum zu finden. Die Rechtmäßigkeit der bereits erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt. Die bei der
          Registrierung erfassten Daten werden von uns gespeichert, solange Sie
          auf unserer Website registriert sind und werden anschließend gelöscht.
          Gesetzliche Aufbewahrungsfristen bleiben unberührt. Weitere
          Informationen zum Datenschutz des Forums finden Sie unter
          <a
            href="https://airborne-division.de/community/index.php?datenschutzerklaerung"
            target="_blank"
            rel="noreferrer"
          >
            https://airborne-division.de/community/index.php?datenschutzerklaerung
          </a>
        </p>

        <h2 class="air-card-sub-title">
          Erhebung von Zugriffsdaten und Logfiles
        </h2>
        <p class="text-justify">
          Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
          berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
          über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
          (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
          abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
          Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst
          Version, das Betriebssystem des Nutzers und die IP-Adresse.
        </p>
        <p class="text-justify">
          Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
          Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von
          maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren weitere
          Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
          endgültigen Klärung des jeweiligen Vorfalls von der Löschung
          ausgenommen.
        </p>

        <h2 class="air-card-sub-title">Umgang des Nutzers</h2>
        <p class="text-justify">
          Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft
          darüber zu erhalten, welche personenbezogenen Daten über Sie
          gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung
          falscher Daten und auf die Verarbeitungseinschränkung oder Löschung
          Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr
          Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass
          Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde
          bei der zuständigen Aufsichtsbehörde einreichen.
        </p>

        <h2 class="air-card-sub-title">Widerspruchsrecht</h2>
        <p class="text-justify">
          Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch
          machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder
          Zeit widersprechen. Wenn Sie eine Berichtigung, Sperrung, Löschung
          oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen
          Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder
          Verwendung Ihrer personenbezogenen Daten haben oder erteilte
          Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende
          E-Mail-Adresse:
          <a class="alternate-text-link" href="mailto:kontakt@airborne-division.de"
            >kontakt@airborne-division.de</a
          >
        </p>
      </air-card>

    </div>
    <div class="col-12">

      <air-card class="mt-2">

        <h1 class="text-center air-card-title mb-0">Externer Inhalt</h1>
        <h2 class="air-card-sub-title">Erläuterung</h2>
        <p class="text-justify">
          Die Seite der 101st Airborne Division kann externen Inhalt nachladen.
          Dazu gehören unter anderem YouTube und Twitter. Beim Nachladen von
          externen Inhalten werden Cookies der jeweiligen Seite gesetzt. Diese
          Cookies können zur persönlichen Identifizierung und zur Nachverfolgung
          genutzt werden.
        </p>

        <h2 class="air-card-sub-title">Aktuell erlaubte externe Inhalte</h2>
        <p>
          <strong *ngIf="isAllExternalContentAllowed()">Es sind alle externen Inhalte erlaubt</strong>
          <strong *ngIf="!isAnyExternalContentAllowed()">Es sind keine externen Inhalte erlaubt</strong>
        </p>

        <p-chip
          *ngFor="let name of currentAllowedExternalContent"
          class="mr-2"
          label="{{name}}"
          [removable]="true"
          (click)="removeExternalSourceFromList(name)"
        ></p-chip>

        <h2 class="air-card-sub-title mt-2">Generelle Erlaubnis erteilen oder widerrufen</h2>

        <air-button
          [disabled]="externalContentService.isAllExternalContentAllowed()"
          [noActions]="true"
          (click)="allowAllExternalContent()"
          class="mr-1"
        >
          Alle externen Inhalte erlauben
        </air-button>

        <air-button
          [disabled]="!externalContentService.isAnyExternalContentAllowed()"
          [noActions]="true"
          (click)="removeAllExternalContentPermissions()"
        >
          Nachladen von externen Inhalten widerrufen
        </air-button>
      </air-card>
    </div>
  </div>
  <div class="w-full text-center mt-2 mb-2 opacity-20">
    <img
      ngSrc="./assets/icons/US_101st_Airborne_Division_patch-1.svg"
      width="200"
      height="200"
      alt="division patch logo"
    />
  </div>
</air-main-layout>
