<air-main-layout *ngIf="isLoading">

  <div class="grid">
    <div class="col">

      <air-card>
        <div class="loading">
          <div class="loading-animation text-center">
            <!-- <p-progressBar mode="indeterminate"></p-progressBar> -->
            <span class="text-xs">Die Airborne arbeitet ...</span>
            <img
              ngSrc="assets/images/FinalLoop128.gif"
              alt="Airborne is working ..."
              width="20"
              height="20"
              class="relative icon"
              style="top: 5px"
            >
          </div>
        </div>
      </air-card>

    </div>
  </div>

</air-main-layout>
