<air-main-layout>
  <div class="grid w-full">
    <div class="col">
      <air-card>
        <div class="grid w-full">
          <div class="col text-center pt-5">
            <fa-icon [icon]="faSadCry" size="3x"></fa-icon>
            <h1 class="text-6xl">404</h1>
            <h2>Ups, die angeforderte Seite konnte nicht gefunden werden.</h2>
          </div>
        </div>
      </air-card>
    </div>
  </div>
</air-main-layout>
