import { Component, OnInit } from '@angular/core';
import paths from 'src/paths';
import departments from 'src/departments.config';
import {SEOService} from '../../service/seo-service/seo.service';
import {UrlService} from '../../service/url-service/url.service';
import {Router} from '@angular/router';

import {NgOptimizedImage} from "@angular/common";
import {DepartmentCardComponent} from "../../components/department-card/department-card.component";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {AirButtonComponent} from "../../shared/air-button/air-button.component";

@Component({
  standalone: true,
  imports: [
    NgOptimizedImage,
    DepartmentCardComponent,
    MainLayoutComponent,
    AirCardComponent,
    AirButtonComponent
  ],
  selector: 'air-departments-page',
  templateUrl: './departments-page.component.html',
  styleUrls: ['./departments-page.component.scss']
})
export class DepartmentsPageComponent implements OnInit {

  get paths() {
    return paths;
  }

  get departments() {
    return departments;
  }

  constructor(
    protected seoService: SEOService,
    private urlService: UrlService,
    private router: Router,
    ) {}

  ngOnInit(): void {

    this.urlService.setPreviousUrl(this.router.url);

    this.seoService.setPageTitle('Abteilungen');
    this.seoService.updateKeyWords(['abteilungen', 'departments'])
  }
}
