import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, lastValueFrom, map, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import LineUpGroup from '../../types/lineup-group.type';
import LineUpResponse from '../../types/lineup-response.type';
import {MemberData} from '../../pages/profile-page/config/member-data';
import {Badges} from '../../pages/profile-page/config/badges';
import {ERank} from "../../pages/profile-page/config/e-rank";

@Injectable()
export class AdcmRepository {
  constructor(protected http: HttpClient) {}

  getCurrentLineUp() {
    let lineupData = this.http.get(
      environment.baseAdcmUrl + '/open-organization'
    );
    let memberData = this.http.get(environment.baseAdcmUrl + '/open-member');

    return forkJoin([lineupData, memberData]).pipe(
      map((data) => {
        let luData = new LineUpResponse();

        const company = (data[0] as LineUpGroup[])?.find(
          (elem) => elem.callSign == 'Able'
        );

        if (company) {
          luData.company = company;
        }

        luData.zeus = (data[1] as MemberData[])
          ?.filter(
            (elem) => elem.zeusType == 'PERMANENT' && !elem.archived
          )

        luData.recruits = (data[1] as MemberData[])?.filter(
          (elem) =>
            elem.rankIdentCode == 'PV1' &&
            elem.zeusType != 'PERMANENT' &&
            !elem.archived
        );

        return luData;
      })
    );
  }

  getLineUpData(): Observable<LineUpGroup[]> {
    return this.http.get(environment.baseAdcmUrl + '/open-organization') as Observable<LineUpGroup[]>;
  }

  async getTeamInfoForMember(id: number): Promise<string | null> {

    const response = await lastValueFrom(this.getLineUpData());
    return response.map((comp) => this.findMember(comp, id)).find((el) => el != undefined) || null;
  }

  getMemberInfoById(id: string | null): Observable<MemberData> {
    return this.http.get(environment.baseAdcmUrl + '/open-member/' + id) as Observable<MemberData>;
  }

  getAllMemberInfo(): Observable<MemberData[]> {
    return this.http.get(environment.baseAdcmUrl + '/open-member') as Observable<MemberData[]>;
  }

  getMemberBadgesById(id: number): Observable<Badges> {
    return this.http.get(environment.baseAdcmUrl + '/open-member/badges/' + id) as Observable<Badges>;
  }

  async getAllProfileNames(): Promise<string[]> {
    const response = await lastValueFrom(this.getAllMemberInfo());
    return response.map((elem) => elem.name);
  }

  protected findMember(data: LineUpGroup, id: number): string | null {

    let person = data.positions.find((p) => p.member?.id == id);
    if (person) {
      return data.callSign;
    } else {
      let findSubMember = null;
      for (let i = 0; i < data.children.length; i++) {
        findSubMember = this.findMember(data.children[i], id);
        if (findSubMember) {
          return data.callSign + '-' + findSubMember;
        }
      }
    }

    return null;
  }
}

export default AdcmRepository;
/// API
/// Line Up general: https://backend.adcm.airborne-division.de/open-organization
/// Alle Profile: https://backend.adcm.airborne-division.de/open-member
/// Bestimtes Profil: https://backend.adcm.airborne-division.de/open-member/{id}
/// Profil Badges: https://backend.adcm.airborne-division.de/open-member/badges/{id}
