<air-card class="air-card-fill-h" [headerTitle]="title">
  <p class="text-justify">{{ description }}</p>
  <h3 *ngIf="departmentData?.chairman" class="air-chairman">
    Ansprechpartner:
    <a
      class="air-department-link"
      [routerLink]="['/profile', departmentData?.chairman?.name?.toLowerCase()]"
      >{{ departmentData?.chairman?.name }}</a
    >
  </h3>
  <ul class="air-list">
    <li *ngFor="let member of departmentData?.members">
      <a
        class="air-department-link"
        [routerLink]="['/profile', member.name.toLowerCase()]"
      >
        <fa-icon class="mr-1" [icon]="faUser"></fa-icon>
        {{ member.team ? member.team + ' ' + member.name : member.name }}
      </a>
    </li>
  </ul>
</air-card>
