<air-loading [isLoading]="loading() && !hasError()"></air-loading>
<div *ngIf="hasError()" style="text-align: center">Fehler beim Laden der Daten. Sehr wahrscheinlich Hoegis Fehler 😉</div>

<air-main-layout *ngIf="!loading() && !hasError()">

  <div class="grid w-full mb-3">
    <div class="col-12 md:col-6 md:col-offset-3 px-1">
      <air-line-up-leader-card
        [lineUpData]="company()"
        leaderPosition="CO"
        [secondLinePositions]="['1SG', 'CMED']"
        [thirdLinePositions]="['SRTO', 'SUPSGT']"
      ></air-line-up-leader-card>
    </div>
  </div>

  <ng-container *ngFor="let platoon of company()?.children">
    <div class="grid w-full">
      <div class="col-12 md:col-6 md:col-offset-3 px-1">
        <air-line-up-leader-card
          [lineUpData]="platoon"
          [secondLinePositions]="['PL', 'PSG']"
          [thirdLinePositions]="['PMED', 'RTO']"
        ></air-line-up-leader-card>
      </div>
    </div>
    <div class="grid w-full mb-6">
      <ng-container *ngFor="let squad of platoon?.children; let i = index">
        <div [ngClass]="'col-12 md:col-3 px-1' + (i == 0 && platoon.children.length < 4 ? ' md:col-offset-' + (5 - platoon.children.length) : '')">
          <air-line-up-squad-card
            [title]="
              company().callSign +
              ' ' +
              platoon.callSign +
              '-' +
              squad.callSign
            "
            [squadData]="squad"
          ></air-line-up-squad-card>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="grid w-full">
    <div class="col-12 md:col-6 md:col-offset-3 px-1">
      <air-card
        class="air-line-up-card"
        [headerTitle]="'Zeuse'"
        [centerHeaderTitle]="true"
      >
        <air-lu-item-paragraph
          *ngFor="let zeus of zeus()"
          [title]="'OPSGT'"
          [text]="zeus.name"
          [rankName]="zeus.rankIdentCode"
          [hasNarrowItems]="false"
          [id]="zeus.id"
        ></air-lu-item-paragraph>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col-12 md:col-6 md:col-offset-3 px-1">
      <air-card
        class="air-line-up-card"
        [headerTitle]="'Rekruten'"
        [centerHeaderTitle]="true"
      >
        <div class="recruits">
          <div *ngFor="let recruit of recruits()">
            <air-lu-item-paragraph
              [title]="'PV1'"
              [text]="recruit.name"
              [rankName]="'PV1'"
              [hasNarrowItems]="false"
              [id]="recruit.id"
            ></air-lu-item-paragraph>
          </div>
        </div>
      </air-card>
    </div>
  </div>
</air-main-layout>
