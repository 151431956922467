import { Component, Input, OnInit } from '@angular/core';
import { DataTableHead } from './config/data-table-head';
import { DataTableValue } from './config/data-table-value';
import { Table, TableModule } from 'primeng/table';
import {Event} from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { SharedModule } from 'primeng/api';
import { NgIf, NgFor, NgStyle, NgClass } from '@angular/common';
import { AirCardComponent } from '../air-card/air-card.component';
import { MainLayoutComponent } from '../layouts/main-layout/main-layout.component';

@Component({
    selector: 'air-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
    standalone: true,
    imports: [MainLayoutComponent, AirCardComponent, NgIf, TableModule, SharedModule, InputTextModule, NgFor, NgStyle, NgClass]
})
export class DataTableComponent<TValue> implements OnInit {
  @Input() tableHeadInfo: DataTableHead[];
  @Input() values: DataTableValue<TValue>[];
  @Input() caption: string;
  @Input() isMainCaption: boolean = false;
  @Input() scrollable = false;
  @Input() scrollHeight: string;
  @Input() globalFilterActive = false;

  constructor() {}

  ngOnInit(): void {}

  filter(tableElement: Table, event: any) {
    tableElement.filterGlobal(event.target.value, 'contains')
  }
}
