import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MentorData } from './config/mentor-data';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MentorlistRepository {
  constructor(protected http: HttpClient) {}

  protected getModelName(): string {
    return `/api/mentorlist`;
  }

  getMentorList(): Observable<MentorData[]> {
    return this.http.get<MentorData[]>(
      this.getModelName()
    );
  }
}
