import { Component, Input, OnInit } from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
  standalone: true,
  imports: [
    NgClass
  ],
  selector: 'air-line-up-card-header',
  templateUrl: './line-up-card-header.component.html',
  styleUrls: ['./line-up-card-header.component.scss']
})
export class LineUpCardHeaderComponent implements OnInit {
  @Input() isAlternateHeader: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
