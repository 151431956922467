import LineUpGroup from './lineup-group.type';
import {MemberData} from "../pages/profile-page/config/member-data";

export class LineUpResponse {
  company: LineUpGroup;
  recruits: MemberData[];
  zeus: MemberData[];
}

export default LineUpResponse;
