import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import paths from 'src/paths';
import {ThemeService} from '../../service/theme-service/theme.service';
import {SEOService} from '../../service/seo-service/seo.service';

import {NgOptimizedImage} from "@angular/common";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirButtonComponent} from "../../shared/air-button/air-button.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {GridComponent} from "../../components/grid/grid.component";
import {AirCard2Component} from "../../components/air-card2/air-card2.component";

@Component({
  standalone: true,
  imports: [
    NgOptimizedImage,
    MainLayoutComponent,
    AirButtonComponent,
    AirCardComponent,
    GridComponent,
    AirCard2Component
  ],
  selector: 'air-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageComponent implements OnInit {

  readonly #themeService = inject(ThemeService);
  readonly #seoService = inject(SEOService);

  ngOnInit(): void {

    this.#seoService.setPageTitle('Wer wir sind');
    this.#seoService.updateKeyWords(['wer wir sind'])
  }

  get getThemeShortName() {
    return this.#themeService.getCurrentThemeNameShort();
  }

  get paths() {
    return paths;
  }
}
