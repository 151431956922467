import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf, NgTemplateOutlet } from '@angular/common';

export enum SeverityLevel {
  Primary,
  Secondary,
  Info,
  Success,
  Help,
  Warning,
  Error
}

export enum ButtonStyleType {
  Normal,
  Raised,
  Rounded,
  Outlined
}

export enum ButtonSize {
  Normal,
  Small,
  Large
}

@Component({
    selector: 'air-button',
    templateUrl: './air-button.component.html',
    styleUrls: ['./air-button.component.scss'],
    standalone: true,
    imports: [NgIf, NgTemplateOutlet, RouterLink]
})
export class AirButtonComponent implements OnInit {
  @Input() link: string = '';
  @Input() openInNewTab: boolean = false;
  @Input() severity: SeverityLevel = SeverityLevel.Primary;
  @Input() styleTypes: ButtonStyleType;
  @Input() size: ButtonSize = ButtonSize.Normal;
  @Input() noActions = false;
  @Input() disabled = false;

  constructor() {}

  ngOnInit(): void {}

  get linkTarget() {
    return this.link?.toLocaleLowerCase().startsWith('https') ||
      this.openInNewTab
      ? 'new'
      : '_self';
  }

  get getLink(): string | undefined {

    if (this.noActions) {
      return;
    }

    if (this.link?.toLocaleLowerCase().startsWith('https')) {
      return this.link;
    }

    if (!this.link?.toLocaleLowerCase().startsWith('/')) {
      return '/' + this.link;
    }

    return this.link;
  }

  private unionHasValue = <T extends number>(union: T, value: T) =>
    (union & value) === value;

  applyClasses() {
    return this.severityToClass(this.severity) + this.sizeToClass(this.size);
  }

  private styleToClass() {
    let classes = '';
    /*
    if (this.unionHasValue(this.styleTypes, ButtonStyleType.Normal)) {
      classes += 'p-button ';
      console.log('normal');
    }

    if (this.unionHasValue(this.styleTypes, ButtonStyleType.Raised)) {
      classes += 'p-button-raised ';
      console.log('raised');
    }

    if (this.unionHasValue(this.styleTypes, ButtonStyleType.Outlined)) {
      classes += 'p-button-outlined ';
      console.log('outlined');
    }

    if (this.unionHasValue(this.styleTypes, ButtonStyleType.Rounded)) {
      classes += 'p-button-rounded ';
      console.log('rounded');
    }*/

    return classes.trim();
  }

  severityToClass(type: SeverityLevel) {
    let baseClass = 'p-button p-button-raised';

    switch (type) {
      case SeverityLevel.Secondary: {
        return baseClass + ' p-button-secondary';
      }

      case SeverityLevel.Success: {
        return baseClass + ' p-button-success';
      }

      case SeverityLevel.Info: {
        return baseClass + ' p-button-info';
      }

      case SeverityLevel.Help: {
        return baseClass + ' p-button-help';
      }

      case SeverityLevel.Warning: {
        return baseClass + ' p-button-warning';
      }

      case SeverityLevel.Error: {
        return baseClass + ' p-button-danger';
      }

      // Primary is default
      default: {
        return baseClass;
      }
    }
  }

  sizeToClass(size: ButtonSize) {
    switch (size) {
      case ButtonSize.Large:
        return ' p-button-lg';

      case ButtonSize.Small:
        return ' p-button-sm';

      case ButtonSize.Normal:
      default:
        return '';
    }
  }

  isExternalLink(link: string) {
    return link.toLocaleLowerCase().startsWith('https');
  }
}
