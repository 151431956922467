<p-card class="air-card">
  <ng-template pTemplate="header">
    <div
      [ngClass]="{
        'pl-2 air-card-header': true,
        'text-center': centerHeaderTitle
      }"
      *ngIf="headerTitle"
    >
      {{ headerTitle }}
    </div>
  </ng-template>

  <ng-content></ng-content>
</p-card>
