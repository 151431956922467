export const missionbuilder = {
  chairman: { name: 'Jay' },
  members: [
    { name: 'Darkseth' },
    { name: 'krimpeldicrab' },
    { name: 'Zodiac' }
  ]
};

export const council = {
  // chairman: { name: 'Jason' },
  members: [
    // { name: 'Samael', team: '1-1' },
    // { name: 'Lord Ferum', team: '1-2' },
    // { name: 'Kantiran', team: '1-3' },
    // { name: 'Serenoris', team: '1-4' },
    // { name: 'Fahri', team: '2-1' },
    // { name: 'Jason', team: '2-2' },
    // { name: 'Mick.V', team: '2-3' },
    // { name: 'Klaus', team: '2-4' },
    // { name: 'Matze2306', team: '3-1' },
    // { name: 'Ofe-Smokey', team: '3-2' },
    // { name: 'Joker', team: '3-3' },
    // { name: 'Doc', team: '3-4' }

    // Vertrauenspersonen
    { name: "DerGraue" },
    { name: "Elo" },
    { name: "FlavourDave" },
    { name: "HollyDay" },
    { name: "krimpeldicrab" },
    { name: "Rico" },
    { name: "Yoshka" },
  ]
};

export const events = {
  chairman: { name: 'don' },
  members: [{ name: 'Darkseth' }, { name: 'don' }, { name: 'Volli' }]
};

export const management = {
  members: [{ name: 'Jay' }, { name: 'Hoegnison' }, { name: 'don' }]
};

export const recruiter = {
  chairman: { name: 'Hoegnison' },
  members: [{ name: 'Dexx' }, { name: 'Joker' }, { name: 'Hoegnison' }]
};

export const trainer = {
  chairman: { name: 'Hoegnison' },
  members: [
    { name: 'Hoegnison' },
    { name: 'Zero' },
    { name: 'Ghost' },
  ]
};

export const storywriter = {
  chairman: { name: 'PhiJaSko' },
  members: [{ name: 'Dexx' }, { name: 'Asathor456' }, { name: 'Baelfrost' }]
};

export const engineering = {
  chairman: { name: 'Jay' },
  members: [
    { name: 'don' },
    { name: 'HerrBasti' },
    { name: 'Hoegnison' },
    { name: 'Jay' }
  ]
};

export default {
  missionbuilder,
  council,
  events,
  management,
  recruiter,
  trainer,
  storywriter,
  engineering
};
