import {Component, Input, OnInit} from '@angular/core';
import {ThemeService} from "../../service/theme-service/theme.service";
import {ThemeDetails} from "../../service/theme-service/config/theme-details";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-theme-switcher',
    templateUrl: './theme-switcher.component.html',
    styleUrls: ['./theme-switcher.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, FontAwesomeModule]
})
export class ThemeSwitcherComponent implements OnInit {

  availableThemes: ThemeDetails[];
  activeTheme: string;
  @Input() asColorDots = true;

  constructor(
    protected themeService: ThemeService,
  ) { }

  ngOnInit(): void {

    this.availableThemes = this.themeService.getThemeList();
    this.activeTheme = this.themeService.getCurrentThemeName();
  }

  setTheme(themeName: string) {
    this.themeService.setTheme(themeName).subscribe(() => this.ngOnInit());
  }

  selectNextTheme() {

    const currentThemeIndex = this.availableThemes.findIndex(theme => theme.name === this.activeTheme);

    const nextTheme = this.availableThemes[currentThemeIndex + 1];

    if (nextTheme) {
      this.themeService.setTheme(this.availableThemes[currentThemeIndex + 1].name).subscribe(() => this.ngOnInit());
    } else {
      this.themeService.setTheme(this.availableThemes[0].name).subscribe(() => this.ngOnInit());
    }
  }
}
