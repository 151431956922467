<li
  [ngClass]="{
    'air-lineup-item': true,
    narrow: hasNarrowItems,
    'background-hover': !disableLink
  }"
  [pTooltip]="tooltip"
  tooltipPosition="top"
  (click)="handleProfileClick()"
>
  <div class="air-lu-name">
    <span class="air-lu-position">{{ title }}</span>

    <span
      [ngClass]="{
        'font-semibold': true,
        'yellow-link': isJoinUsLink
      }"
    >
      {{ text }}
    </span>
  </div>

  <div *ngIf="rankName" class="air-lineup-image text-center">
    <img
      [class]="'air-image air-rank-' + rankName"
      [ngSrc]="getRankIcon()"
      [alt]="getRankTranslation(rankName).name || rankName"
      [pTooltip]="getRankTranslation(rankName).name || rankName"
      [class.grayscale]="isDarkTheme()"
      tooltipPosition="top"
      width="117px"
      height="135px"
    />
  </div>
</li>
<div class="progress-animation">
  <p-progressBar *ngIf="isProfileLoading" mode="indeterminate"></p-progressBar>
</div>
