import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from '@angular/router';
import AdcmRepository from '../../repositories/adcm/adcm.repository';
import {environment} from '../../../../environments/environment';
import {lastValueFrom} from 'rxjs';
import {MemberData} from './config/member-data';
import {ManagementBadge} from './config/management-badge';
import {Badges} from './config/badges';
import {CombatInfantrymanBadge} from './config/combat-infrantryman-badge';
import {AdditionalBadge} from './config/additional-badge';
import {faTwitch, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {UrlService} from '../../service/url-service/url.service';
import {SEOService} from '../../service/seo-service/seo.service';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {AirborneTranslationService} from "../../service/airborne-translation-service/airborne-translation.service";
import {EPosition} from "./config/e-position";

import {DatePipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {TooltipModule} from "primeng/tooltip";
import {AccordionModule} from "primeng/accordion";
import {LoadingComponent} from "../../shared/loading/loading.component";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@Component({
  standalone: true,
  imports: [RouterLink, NgOptimizedImage, TooltipModule, DatePipe, AccordionModule, NgIf, NgForOf, LoadingComponent, MainLayoutComponent, AirCardComponent, FontAwesomeModule],
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePageComponent implements OnInit {

  readonly #cd = inject(ChangeDetectorRef);
  readonly #adcmRepo = inject(AdcmRepository);
  readonly #urlService = inject(UrlService);
  readonly #seoService = inject(SEOService);
  readonly #route = inject(ActivatedRoute);
  readonly #airborneTranslationService = inject(AirborneTranslationService);

  isLoading = true;
  memberData: MemberData;
  badges: Badges;

  origin: string | null;

  faTwitch = faTwitch as IconDefinition;
  faYoutube = faYoutube as IconDefinition;

  async ngOnInit() {
    this.#urlService.previousUrl$.subscribe(
      (previousUrl) => (this.origin = previousUrl)
    );

    await this.getData();

    this.#seoService.setPageTitle(this.memberData.name);
    this.#seoService.updateKeyWords([
      'aufstellung',
      'lineup',
      this.memberData.name
    ]);
  }

  getUniformPath() {
    return (
      environment.baseAdcmUrl +
      '/public-assets/uniforms/' +
      this.memberData.forumUserId +
      '.png'
    );
  }

  getRankTranslation() {
    return this.#airborneTranslationService.getRanksTranslation(this.memberData.rankIdentCode);
  }

  getRankIconPath() {
    return 'assets/images/ranks/' + this.memberData.rankIdentCode + '.svg';
  }

  getPositionTranslation() {

    let position = this.memberData.position?.name || '';
    if (this.memberData.zeusType === 'PERMANENT') position = EPosition.OPSGT;

    return this.#airborneTranslationService.getPositionTranslation(position);
  }

  getManagementBadgePath(badge: ManagementBadge) {
    return environment.baseAdcmUrl + '/' + badge.imageURL;
  }

  getAdditionalBadgePath(badge: AdditionalBadge) {
    return environment.baseAdcmUrl + '/' + badge.imageURL;
  }

  getCombatInfantryManBadgePath(badge: CombatInfantrymanBadge) {
    return environment.baseAdcmUrl + '/' + badge.imageURL;
  }

  getManagementBadgeTranslation(badge: ManagementBadge) {
    return this.#airborneTranslationService.getBadgeTranslation(badge.badge);
  }

  getAdditionalBadgeTranslation(badge: AdditionalBadge) {
    return this.#airborneTranslationService.getBadgeTranslation(badge.badge);
  }

  getCombatInfantryManBadgeTranslation(badge: CombatInfantrymanBadge) {
    return this.#airborneTranslationService.getBadgeTranslation(badge.badge);
  }

  getStripeDescription() {
    return this.#airborneTranslationService.getStripeDescription(this.memberData.joinDate);
  }

  private async getData() {
    this.memberData = this.#route.snapshot.data['memberData'];

    this.badges = await lastValueFrom(
      this.#adcmRepo.getMemberBadgesById(this.memberData.id)
    );

    this.isLoading = false;
    this.#cd.markForCheck();
  }

  getSortedRibbons() {
    return this.memberData.ribbons.sort((a, b) => a.sortNumber - b.sortNumber);
  }

  isMemberActive(): boolean {
    return !this.memberData.archived;
  }
}
