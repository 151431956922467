import {ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {SEOService} from './modules/service/seo-service/seo.service';
import {ThemeService} from './modules/service/theme-service/theme.service';
import {ExternalContentService} from './modules/service/external-content-service/external-content.service';
import {SeoBuilderService} from './modules/service/seo-builder/seo-builder.service';
import {faMoon, faSun} from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './modules/menu/footer/footer.component';
import { NavBarComponent } from './modules/menu/navbar/nav-bar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, FooterComponent, NavBarComponent],
})
export class AppComponent implements OnInit {

  readonly #primeNGConfig = inject(PrimeNGConfig);
  readonly #seoService = inject(SEOService);
  readonly #seoBuilder = inject(SeoBuilderService);
  readonly #themeService = inject(ThemeService);
  readonly #externalContentService = inject(ExternalContentService);
  readonly #cd = inject(ChangeDetectorRef);

  ngOnInit(): void {

    this.#primeNGConfig.ripple = false;
    this.buildHeadInfo();
    this.buildThemes();
    this.#externalContentService.buildLocalstorageItems();
    this.#cd.markForCheck();
  }

  protected buildHeadInfo() {

    this.#seoService.setMainTitle('101st Airborne Division');
    this.#seoService.setDefaultKeywords([
      'arma',
      'arma 3',
      'arma3',
      'milsim',
      'clan',
      'mission',
      'einsatz',
      'german',
      'deutsch',
      'community',
      'realismus',
      'realism',
      'airborne',
      'airborne division',
      '101st',
      '101AD',
    ]);

    this.#seoBuilder
      .addMetaTag('og:url', 'https://www.airborne-division.de/')
      .createLink('canonical', 'https://www.airborne-division.de/')
      .addMetaTag('description', 'Arma3 MilSim-Clan. Gegründet 2007 und sehr aktiv in der Arma3 Community. Wenn Du einen realistischen Spielstil bevorzugst, dann schau Dich auf unserer Seite um.')
      .addMetaTag('robots', 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1')
      .addMetaTag('google-site-verification', 'KSWALuFWSnsAwPU2Jhc7AyHs7G5_qmNDcw8qyo0u_Ag')
      .addMetaTag('keywords', this.#seoService.getDefaultKeywords().join(', '))
      .addMetaTag('viewport', 'width=device-width, initial-scale=1.0, shrink-to-fit=no')
      .addMetaTag('msapplication-TileColor', '#000000')
      .addMetaTag('msapplication-config', '/favicon/browserconfig.xml')
      .addMetaTag('theme-color', '#000000')
      .addMetaTag('og:locale', 'de_DE')
      .addMetaTag('og:type', 'website')
      .addMetaTag('og:title', '101st Airborne Division')
      .addMetaTag('og:description', 'Arma3 MilSim-Clan. Gegründet 2007 und sehr aktiv in der Arma3 Community. Wenn Du einen realistischen Spielstil bevorzugst, dann schau Dich auf unserer Seite um.')
      .addMetaTag('og:site_name', '101st Airborne Division')
      .createLink('icon', 'assets/icons/favicon.png', [ { name: 'type', value: 'image/x-icon' }])
      .createLink('apple-touch-icon', '/favicon/apple-touch-icon.png', [ { name: 'sizes', value: '180x180' } ])
      .createLink('icon', '/favicon/airborne_logo-1-32x32.png', [ { name: 'type', value: 'image/png' }, { name: 'sizes', value: '32x32' } ])
      .createLink('icon', '/favicon/airborne_logo-1-192x192.png', [ { name: 'type', value: 'image/png' }, { name: 'sizes', value: '192x192' } ])
      .createLink('mask-icon', '/favicon/safari-pinned-tab.svg', [ { name: 'color', value: '#000000'} ])
      .createLink('alternate', '/feed.xml', [ { name: 'type', value: 'application/rss+xml' } ])
      // .createLink('manifest', '/favicon/site.webmanifest')
      .build();
  }

  protected buildThemes() {

    this.#themeService
      .addTheme('theme-light', faSun as IconDefinition)
      .addTheme('theme-dark', faMoon as IconDefinition)
      .build();
  }
}
