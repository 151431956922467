<air-main-layout>
  <div
    class="grid w-12 lg:w-8 xl:w-7 text-center lg:text-left flex-column-reverse lg:flex-row justify-content-center mt-8 mb-8"
  >
    <div
      class="sm:col-12 lg:col-9 align-content-center justify-content-center flex flex-column intro"
    >
      <div class="headline">101st Airborne Division</div>
      <div class="mb-3 subline">Deutsche Arma MilSim-Gemeinschaft</div>
      <div class="text text-center lg:text-left">
        MilSim in Arma steht in unseren Augen für die Simulation von
        militärischem Auftreten und Disziplin, von originalgetreuen
        militärischen Strukturen sowie militärischer Taktiken im Feld.
        <div class="mt-5 text-center lg:text-left">
          <air-button [link]="paths.rekrutierung">
            Erfahre mehr - Rekrutierungscenter
          </air-button>
        </div>
      </div>
    </div>
    <div
      class="sm:col-12 lg:col-3 text-center align-content-center justify-content-center flex flex-column align-items-center"
    >
      <img
        ngSrc="./assets/icons/US_101st_Airborne_Division_patch-1.svg"
        height="300"
        alt="Airborne Division Logo"
        width="206"
        [priority]="true"
      />
    </div>
  </div>
  <div class="grid w-full">
    <div class="col">
      <air-card headerTitle="Wer wir sind - MilSim und Arma">
        <div class="grid">
          <div class="col-12 md:col-6 air-dual-column-left">
            <img
              class="air-image"
              ngSrc="./assets/images/Main_composition_00364.jpg"
              alt="Ein Einsatz der Airborne in Arma 3"
              height="478" width="850"
              [priority]="true"
            />
          </div>
          <div class="col-12 md:col-6 air-dual-column-right">
            <h3 class="air-card-sub-title">Unsere eigene Definition</h3>
            <p class="text-justify">
              Die Definition von MilSim in Arma variiert von Gemeinschaft zu
              Gemeinschaft. Deswegen ist es uns wichtig, dass unsere Auffassung
              davon möglichst klar formuliert ist.
              <br />
              MilSim in Arma steht in unseren Augen für die Simulation von
              militärischem Auftreten und Disziplin, von originalgetreuen
              militärischen Strukturen sowie militärischer Taktiken im Feld.
              <br />
              Des Weiteren bedeutet MilSim für uns die Darstellung einer
              Einheit. Das impliziert, dass wir immer wieder die gleiche US.
              Army Rifle Company darstellen und wir versuchen in dieser
              Darstellung immer besser zu werden.
            </p>
          </div>
        </div>
        <p class="text-justify">
          Dazu arbeiten wir mit festen Positionen, trainieren regelmäßig
          zusammen und investieren viel Zeit darin, neue Mitglieder in unsere
          Einheit zu integrieren. Die oberste Zielsetzung von MilSim ist für uns
          also das Etablieren eines professionellen und immersiven Umfeldes.
          Wenn dich das interessiert, dann schau dich bei uns im
          Rekrutierungscenter um.
        </p>
        <span class="flex justify-content-center mt-2">
          <air-button [link]="paths.rekrutierung">
            Erfahre mehr - Rekrutierungscenter
          </air-button>
        </span>
        </air-card>
      </div>
    </div>
    <div class="grid w-full">
      <div class="col">
        <air-card headerTitle="Organisation und Verwaltung">
          <div class="grid">
            <div class="col-12 md:col-6 pb-0 pr-1">
              <h3 class="air-card-sub-title">Struktur & Aufgabenverwaltung</h3>
              <p class="text-justify">
                In unserem Clan gibt es eine geordnete Verwaltung, in deren
                Bereichen jedes etablierte Mitglied eine wichtige Position
                übernehmen kann und später auch sollte. Die Hierarchie außerhalb
                des Spiels ist absichtlich flach, denn wir bewegen uns
                untereinander auf Augenhöhe. Jedes Mitglied im Clanrat hat zudem
                die Möglichkeit, sich bei einer Abstimmung zu beteiligen und so
                wichtige Entscheidungen des Clans zu beeinflussen.
              </p>
            </div>
            <div class="col-12 md:col-6 pb-0 pl-1">
              <img
                class="air-image"
                ngSrc="./assets/images/Main_composition_00479.jpg"
                alt="Ein Einsatz der Airborne in Arma 3"
                height="455"
                width="850"
                [priority]="true"
              />
            </div>
          </div>
          <span class="flex justify-content-center mt-2">
          <air-button [link]="paths.einheit.children.abteilungen.full">
            Erfahre mehr - Verwaltung
          </air-button>
        </span>
        </air-card>
      </div>
    </div>

    <div class="grid w-full">
      <div class="col-12 md:col-6 air-outer-dual-column-left">
        <air-card
          class="air-card-fill-h"
          headerTitle="Die 101st Airborne Division"
        >
          <div class="text-center">
            <img
              ngSrc="./assets/icons/US_101st_Airborne_Division_patch-1.svg"
              height="150"
              alt="Airborne Division Logo"
              width="103"
            />
            <h3 class="air-card-sub-title">Luftlandedivision der U.S. Army</h3>
          </div>
          <p class="text-justify">
            Im Spiel Arma 3 stellen wir die 101st Airborne Division dar. Die 101st
            ist eine sehr flexible Luftlandedivision der Vereinigten Staaten.
          </p>
          <p class="text-justify">
            Sie wurde im Jahr 1942 gegründet und ist bis heute eine der
            schnellsten Eingreiftruppen der Vereinigten Staaten. Das Wappen der
            101st Airborne Division zeigt einen Weißkopfadler. Durch das Wappen
            bekam die Division den Spitznamen “The Screaming Eagles”. Auch wenn
            heute keine Fallschirmjägeroperationen mehr durchgeführt werden, so
            behielt die 101st aus Tradition im Namen den Begriff “Airborne”. Die
            Truppen werden heute hauptsächlich mit Hubschraubern verlegt. Diese
            Einheit stellen wir mit Stolz und mit größt möglichem Realismus dar.
          </p>
          <span class="flex justify-content-center mt-2">
          <air-button
            link="https://de.wikipedia.org/wiki/101st_Airborne_Division_(Vereinigte_Staaten)"
            [openInNewTab]="true"
          >
            Erfahre mehr - 101st Airborne Division
          </air-button>
        </span>
        </air-card>
      </div>
      <div class="col-12 md:col-6 air-outer-dual-column-right">
        <air-card class="air-card-fill-h" headerTitle="Arma 3">
          <div class="text-center">
            <img
              class="air-arma-logo"
              ngSrc="./assets/icons/arma3logo3-150x150.jpg"
              alt="Arma3 Logo"
              height="120"
              width="120"
            />
            <h3 class="air-card-sub-title">Die Militärsimulation unserer Wahl</h3>
          </div>
          <p class="text-justify">
            Arma 3 ist eine von Bohemia Interactive entwickelte Militärsimulation.
            Das Spiel ist in der nahen Zukunft angesiedelt. Arma besitzt seither
            eine sehr aktive Mod-Community. Daher ist es uns möglich, sämtliche
            Inhalte im Spiel in die Gegenwart zu verschieben und auf aktuelle
            Ausrüstung zurückzugreifen. Arma 3 zeichnet sich durch hohe Dynamik,
            ansprechende Grafik und sehr individuelle Möglichkeiten im Spiel aus.
          </p>
          <span class="flex justify-content-center mt-2">
          <air-button link="https://arma3.com" [openInNewTab]="true">
            Erfahre mehr - Arma 3
          </air-button>
        </span>
        </air-card>
      </div>
    </div>
  </air-main-layout>
