import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal} from '@angular/core';
import {DataTableValue} from '../../shared/data-table/config/data-table-value';
import {DataTableHead} from '../../shared/data-table/config/data-table-head';
import {MentorlistRepository} from '../../repositories/mentorlist/mentorlist.repository';
import {catchError, take, tap} from 'rxjs';
import {MentorData} from '../../repositories/mentorlist/config/mentor-data';
import {SEOService} from '../../service/seo-service/seo.service';

import {NgIf} from "@angular/common";
import {LoadingComponent} from "../../shared/loading/loading.component";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {DataTableComponent} from "../../shared/data-table/data-table.component";

@Component({
  standalone: true,
  imports: [
    NgIf,
    LoadingComponent,
    MainLayoutComponent,
    DataTableComponent
  ],
  selector: 'app-mentorlist-page',
  templateUrl: './mentorlist-page.component.html',
  styleUrls: ['./mentorlist-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentorlistPageComponent implements OnInit {

  readonly #mentorlistRepo = inject(MentorlistRepository);
  readonly #seoService = inject(SEOService);
  readonly #cd = inject(ChangeDetectorRef);

  mentorList: DataTableValue<[] | RegExpMatchArray>[];
  mentorLeaderboard: { content: (string | number)[] }[];
  header: DataTableHead[];
  headerMentorLeaderBoardList: DataTableHead[];
  readonly isLoading = signal(true);
  readonly hasError = signal(false);

  ngOnInit(): void {

    this.#seoService.setPageTitle('Mentorenliste');
    this.#seoService.updateKeyWords(['mentorenliste', 'mentorlist'])

    this.header = [
      { label: 'Mentor' },
      { label: 'Rekrut' },
      { label: 'Datum' }
    ];

    this.headerMentorLeaderBoardList = [
      {
        label: 'Anzahl Schulungen',
        classes: 'air-number-column'
      },
      { label: 'Mentor' },
      { label: 'Letzter Rekrut' }
    ];

    this.#mentorlistRepo
      .getMentorList()
      .pipe(
        take(1),
        catchError((error) => {

          console.error('Error while fetching mentorlist data', error)
          this.hasError.set(true);
          this.#cd.markForCheck();

          return [];
        }),
        tap(result => {

          this.buildMentorList(result);
          this.buildMentorLeaderboard(result);
          this.isLoading.set(false);
          this.#cd.markForCheck();
        })
      )
      .subscribe();
  }

  protected buildMentorList(mentorData: MentorData[]) {
    this.mentorList = mentorData.map((data) => {
      const regex = / +([^<]*)/g;
      const transformedMentorData = data.message.match(regex);

      return { content: transformedMentorData || [] };
    });
  }

  protected buildMentorLeaderboard(mentorData: MentorData[]) {
    const matchedRegexArray = mentorData.map((data) => {
      const regex = / +([^<]*)/g;
      return data.message.match(regex);
    });

    let groupedMentorData = matchedRegexArray.reduce(
      (previous: any, current: any) => {
        previous[current[0].trim()] = [
          ...(previous[current[0].trim()] || []),
          current
        ];
        return previous;
      },
      {}
    );

    this.mentorLeaderboard = Object.entries(groupedMentorData).map(
      (mentorData: any) => {
        const tableData = [(mentorData[1] as []).length, mentorData[0], mentorData[1][0][1]];

        return {
          content: tableData || []
        };
      }
    );

    this.mentorLeaderboard
      .sort((a, b) =>
        a.content[0] > b.content[0] ? 1 : b.content[0] > a.content[0] ? -1 : 0
      )
      .reverse();

    const firstPlaceIcon = './assets/icons/2279848_first_place_winner.png';
    const secondPlaceIcon = './assets/icons/2279851_second_place_winner.png';
    const thirdPlaceIcon = './assets/icons/2279887_third_place_winner.png';

    this.mentorLeaderboard[0].content[1] = `<img src=${firstPlaceIcon} alt="first place" width="15" height="15">&nbsp;${this.mentorLeaderboard[0].content[1]}`;
    this.mentorLeaderboard[1].content[1] = `<img src=${secondPlaceIcon} alt="second place" width="15" height="15">&nbsp;${this.mentorLeaderboard[1].content[1]}`;
    this.mentorLeaderboard[2].content[1] = `<img src=${thirdPlaceIcon} alt="third place" width="15" height="15">&nbsp;${this.mentorLeaderboard[2].content[1]}`;
  }
}
