import {Component, OnInit} from '@angular/core';
import paths from '../../../../paths';
import {faTwitter, faWikipediaW, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {faAddressCard, faChartSimple, faHome, faListAlt} from '@fortawesome/free-solid-svg-icons';
import {FooterMenuBuilder} from './builder/footer-menu-builder.service';
import {FooterMenu} from './config/footer-menu';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { ThemeSwitcherComponent } from '../../shared/theme-switcher/theme-switcher.component';

@Component({
    selector: 'air-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [ThemeSwitcherComponent, NgFor, NgIf, RouterLink, RouterLinkActive, FontAwesomeModule]
})
export class FooterComponent implements OnInit {

  footerMenuConfig: FooterMenu;

  constructor(
    protected footerMenuBuilder: FooterMenuBuilder,
  ) {}

  ngOnInit(): void {
    this.buildMenuItems();
  }

  protected buildMenuItems() {

    this.footerMenuBuilder
      .clear()
      .addItem('airborne-division.de', paths.home, faHome as IconDefinition)
      .addItem('Wiki', 'https://wiki.airborne-division.de', faWikipediaW as IconDefinition, true)
      .addItem('Twitter', 'https://twitter.com/101STMILSIM', faTwitter as IconDefinition, true)
      .addItem('Medien', paths.medien, faYoutube as IconDefinition)
      .addItem('Statistiken', paths.statistics, faChartSimple as IconDefinition)
      .addItem('Datenschutz', paths.datenschutz, faListAlt as IconDefinition)
      .addItem('Impressum', paths.impressum, faAddressCard as IconDefinition);

    this.footerMenuConfig = this.footerMenuBuilder.buildConfig();
  }
}
