<header
  class="navbar mb-2"
  [class.with-shadow]="true"
  (mouseleave)="desktopMenu.closeSubMenu()"
>
  <div class="mobile-toggle-container">
    <div class="left ml-1 mt-1">
      <fa-icon class="toggle p-3" [icon]="faBars" size="lg" (click)="setSidebarVisibleState(true)"></fa-icon>
    </div>

    <div class="right">
      <div class="text-content">
        <div class="headline">101st Airborne Division</div>
        <div class="subline">Deutsche Arma MilSim-Gemeinschaft</div>
      </div>
      <div>
        <a class="logo right" routerLink="/">
          <img
            [alt]="headerMenuConfig.icon.alt"
            [ngSrc]="headerMenuConfig.icon.path"
            width="50"
            height="50"
          >
        </a>
      </div>
    </div>
  </div>

  <air-desktop-menu
    #desktopMenu
    [config]="headerMenuConfig"
  ></air-desktop-menu>

  <air-mobile-menu
    [config]="headerMenuConfig"
    [sidebarVisible$]="sidebarVisible"
  ></air-mobile-menu>
</header>
