<air-loading [isLoading]="loading() && !hasError()"></air-loading>
<div *ngIf="hasError()" style="text-align: center">Fehler beim Laden der Daten. Sehr wahrscheinlich Hoegis Fehler 😉</div>

<air-main-layout *ngIf="!loading() && !hasError()">

  <div class="grid w-full">
    <div class="col">
      <air-card>
        <h1 class="text-center">Live-Statistiken</h1>

        <div class="statistics-container mb-8">
          <div class="statistics-header">Statistiken zu unseren Mitgliedern</div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Mitglieder
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              <p-accordion>
                <p-accordionTab header="Liste">
                  <div class="font-bold" *ngFor="let member of activeMembers">
                    <a [routerLink]="['/', 'profile', member.name]">{{ member.name }}</a>
                  </div>
                </p-accordionTab>
              </p-accordion>
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Anzahl Mitglieder
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              {{ statisticMemberCount }}
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Anzahl Platoons
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              {{ statisticPlatoonCount }}
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Mitglieder in der Kompanie
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">

              <p-accordion>
                <p-accordionTab [selected]="true">
                  <ng-template pTemplate="header">

                    <img
                      ngSrc="assets/images/company-flag.png"
                      alt="Die Flagge unserer Company. Zu sehen ist das Abzeichen der dritten Brigade der 101st Airborne Division (Rakkansan). Darunter zu sehen sind Elemente der Regimentswappen der Teileinheiten unserer Company. Der weiße Blitz für das 506 Infantry Regiment und das weiße Quadrat auf grünem Grund für das 33 Cavalry Regiment. Es handelt sich bei dem Logo um eine Eigenkreation."
                      pTooltip="Die Flagge unserer Company. Zu sehen ist das Abzeichen der dritten Brigade der 101st Airborne Division (Rakkansan). Darunter zu sehen sind Elemente der Regimentswappen der Teileinheiten unserer Company. Der weiße Blitz für das 506 Infantry Regiment und das weiße Quadrat auf grünem Grund für das 33 Cavalry Regiment. Es handelt sich bei dem Logo um eine Eigenkreation."
                      class="pr-2"
                      height="35"
                      width="40"
                    />
                    Mitglieder
                  </ng-template>
                  <div *ngFor="let statistic of statisticCompany" class="mb-4 clear-bottom font-bold">
                    {{ statistic.details.name}}<br><span>{{ statistic.currentMembers }} von {{ statistic.maxMembers }}</span>
                  </div>

                </p-accordionTab>
              </p-accordion>

            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Anzahl Rekruten
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              {{ statisticRecruitCount }}
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Anzahl fester Zeuse
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              {{ statisticZeusCount }}
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Anzahl flexibler Zeuse
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              {{ statisticFlexibleZeusCount }}
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Erstes Mitglied
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              <span>
                <a [routerLink]="['/', 'profile', statisticFirstMember.name]">{{ statisticFirstMember.name }}</a>
              </span>&nbsp;
              <span class="text-xs">(seit {{ toLocaleDateString(statisticFirstMember.joinDate) }})</span>
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Neuestes Mitglied
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              <span>
                <a [routerLink]="['/', 'profile', statisticNewestMember.name]">{{ statisticNewestMember.name }}</a>
              </span>&nbsp;
              <span class="text-xs">(seit {{ toLocaleDateString(statisticNewestMember.joinDate) }})</span>
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Am häufigsten vergebenes Kampagnen-Ribbon
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              <div class="flex w-full gap-3 p-2 ribbon-or-badges-link" [routerLink]="['/', 'statistics', 'ribbon', statisticMostValuableRibbon.ribbon.id]">
                <div class="text-center">
                  <img
                    width="52"
                    height="52"
                    [ngSrc]="statisticMostValuableRibbon.ribbon.imageURL"
                    [alt]="statisticMostValuableRibbon.ribbon.description"
                  >
                </div>
                <div class="info flex flex-column justify-content-center">
                  <div class="font-bold">
                    <span>{{ statisticMostValuableRibbon.ribbon.name }}</span>&nbsp;
                    <span style="font-size: smaller">({{ statisticMostValuableRibbon.members.length }} Mal vergeben)</span> <br>
                    <span style="font-size: smaller">{{ statisticMostValuableRibbon.ribbon.description }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Kampagnen-Ribbons
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              <p-accordion>
                <p-accordionTab header="Ribbons" [selected]="true">

                  <div [routerLink]="['/', 'statistics', 'ribbon', ribbon.ribbon.id]" class="flex w-full gap-3 p-2 ribbon-or-badges-link" *ngFor="let ribbon of statisticRibbons">
                    <div class="text-center pl-2">
                      <img
                        width="52"
                        height="52"
                        [ngSrc]="ribbon.ribbon.imageURL"
                        [alt]="ribbon.ribbon.description"
                      >
                    </div>
                    <div class="info flex flex-column justify-content-center">
                      <div class="font-bold">
                        <span>{{ ribbon.ribbon.name }}</span>&nbsp;
                        <span style="font-size: smaller">({{ ribbon.members.length }} Mal vergeben)</span> <br>
                        <span style="font-size: smaller">{{ ribbon.ribbon.description }}</span>
                      </div>
                    </div>
                  </div>

                </p-accordionTab>
              </p-accordion>
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
              Management-Badges
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              <p-accordion>
                <p-accordionTab header="Badges" [selected]="true">

                  <div [routerLink]="['/', 'statistics', 'management-badge', badge.badge.badge.toLowerCase()]" class="flex w-full gap-3 p-2 ribbon-or-badges-link" *ngFor="let badge of statisticManagementBadges">
                    <div class="text-center">
                      <img
                        width="52"
                        height="52"
                        [ngSrc]="getManagementBadgePath(badge.badge)"
                        [alt]="badge.badge.badge"
                      >
                    </div>
                    <div class="info flex flex-column justify-content-center">
                      <div class="font-bold">
                        <span>{{ getBadgeTranslation(badge.badge.badge).name }}</span>&nbsp;
                        <span style="font-size: smaller">({{ badge.members.length }} Mal vergeben)</span> <br>
                        <span style="font-size: smaller">{{ getBadgeTranslation(badge.badge.badge).description }}</span>
                      </div>
                    </div>
                  </div>

                </p-accordionTab>
              </p-accordion>
            </div>
          </div>

          <div class="statistics-content flex-column md:flex-row border-1 md:border-none">
            <div class="statistics-content-left w-full md:w-5 border-none md:border-1">
             Zusätzliche Badges
            </div>
            <div class="statistics-content-right sw-full md:w-7 border-none md:border-1">
              <p-accordion>
                <p-accordionTab header="Badges" [selected]="true">

                  <div [routerLink]="['/', 'statistics', 'additional-badge', badge.badge.badge.toLowerCase()]" class="flex w-full gap-3 p-2 ribbon-or-badges-link" *ngFor="let badge of statisticAdditionalBadges">
                    <div class="text-center">
                      <img
                        width="52"
                        height="52"
                        [ngSrc]="getAdditionalBadgePath(badge.badge)"
                        [alt]="badge.badge.badge"
                      >
                    </div>
                    <div class="info flex flex-column justify-content-center">
                      <div class="font-bold">
                        <span>{{ getBadgeTranslation(badge.badge.badge).name }}</span>&nbsp;
                        <span style="font-size: smaller">({{ badge.members.length }} Mal vergeben)</span> <br>
                        <span style="font-size: smaller">{{ getBadgeTranslation(badge.badge.badge).description }}</span>
                      </div>
                    </div>
                  </div>

                </p-accordionTab>
              </p-accordion>
            </div>
          </div>


        </div>

      </air-card>
    </div>
  </div>

</air-main-layout>
