import {IconDefinition} from '@fortawesome/fontawesome-svg-core';

export class HeaderMenuItem {

  label: string;
  targetUrl: string;
  isExternal?: boolean;
  subMenu?: HeaderMenuItem[];
  subMenuIconOpen?: IconDefinition;
  subMenuIconClosed?: IconDefinition;

}
