<air-card
  [ngClass]="'air-line-up-card' + (leaderObj ? '' : ' air-compact')"
  [headerTitle]="title || lineUpData?.name || 'Lade...'"
  [centerHeaderTitle]="true"
>
  <div class="grid" *ngIf="leaderObj">
    <div class="col-12 lg:col-6 lg:col-offset-3 air-h-b-border">
      <air-lu-item-paragraph
        [title]="leaderObj.name || ''"
        [text]="leaderObj.member.name || ''"
        [rankName]="leaderObj.member.rankIdentCode || 'PV1'"
        [hasNarrowItems]="hasNarrowItems"
        [id]="leaderObj.member.id"
      ></air-lu-item-paragraph>
    </div>
  </div>

  <div
    class="grid mb-0 mt-1"
    *ngFor="let position of allPositions; let i = index"
  >
    <div
      *ngIf="i % 2 == 0"
      [ngClass]="{
        'pt-2 col-12 lg:col-6': true,
        'air-h-b-border': i + 2 < (allPositions || []).length
      }"
    >
      <air-lu-item-paragraph
        *ngIf="position"
        [title]="position?.name || ''"
        [text]="position?.member?.name || 'nicht besetzt'"
        [rankName]="position?.member?.rankIdentCode || 'PV1'"
        [hasNarrowItems]="hasNarrowItems"
        [id]="position?.member?.id || 0"
      ></air-lu-item-paragraph>
    </div>

    <ng-container *ngIf="i % 2 == 0">
      <div
        [ngClass]="{
          'pt-2 col-12 lg:col-6': true,
          'air-h-b-border': i + 2 < (allPositions || []).length
        }"
      >
        <air-lu-item-paragraph
          *ngIf="
            allPositions && allPositions[i + 1] && allPositions[i + 1].member
          "
          [title]="allPositions[i + 1].name || ''"
          [text]="allPositions[i + 1].member.name"
          [rankName]="allPositions[i + 1].member.rankIdentCode"
          [hasNarrowItems]="hasNarrowItems"
          [id]="allPositions[i + 1].member.id"
        ></air-lu-item-paragraph>
        <air-lu-item-paragraph
          *ngIf="
            !allPositions || !allPositions[i + 1] || !allPositions[i + 1].member
          "
          rankName="PV1"
          text="nicht besetzt"
          [title]="allPositions?.[i + 1]!.name || ''"
        ></air-lu-item-paragraph>
      </div>
    </ng-container>
  </div>
</air-card>
