<a
  *ngIf="isExternalLink(link)"
  [href]="getLink"
  [class]="applyClasses()"
  [target]="linkTarget"
  rel="noreferrer"
  [class.p-disabled]="disabled"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a
  *ngIf="!isExternalLink(link)"
  [routerLink]="getLink"
  [class]="applyClasses()"
  [target]="linkTarget"
  [class.p-disabled]="disabled"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
