import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {HeaderMenu} from '../config/header-menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgOptimizedImage, NgFor, NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'air-desktop-menu',
    templateUrl: './desktop-menu.component.html',
    styleUrls: ['./desktop-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        RouterLink,
        NgOptimizedImage,
        NgFor,
        NgIf,
        RouterLinkActive,
        FontAwesomeModule,
    ],
})
export class DesktopMenuComponent {

  readonly #cd = inject(ChangeDetectorRef);

  @Input() config: HeaderMenu;

  currentOpenSubMenu: string = '';

  openSubMenu(clickedItem: string) {
    this.currentOpenSubMenu = clickedItem || '';
  }

  closeSubMenu() {
    setTimeout(() => {
      this.currentOpenSubMenu = '';
      this.#cd.markForCheck();
    }, 250);
  }
}
