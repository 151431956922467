import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import AdcmRepository from '../repositories/adcm/adcm.repository';
import {MemberData} from "../pages/profile-page/config/member-data";
import {ManagementBadgeStatistic} from "../types/management-badge-statistic";

@Injectable()
export class AdditionalBadgeDataResolver {

  readonly #adcmRepo = inject(AdcmRepository);
  readonly #router = inject(Router);

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const badgeName = route.paramMap.get('name'); // TODO rh später auf id umbauen, im moment müssen wir mit dem namen leben

    if (!badgeName) {
      return;
    }

    const members = await this.getBadgeMembers(badgeName);

    if (members.length === 0) {

      console.warn('Wrong badge given. Routing to statistics.');
      this.#router.navigate(['/', 'statistics']);
      return;
    }

    const badge = new ManagementBadgeStatistic();
    badge.members = members;
    badge.badge = members[0].additionalBadges.filter(badge => badge.badge.toLowerCase() === badgeName.toLowerCase())[0]; // TODO rh crappy way to find the first ribbon, but array.find can return undefined

    return badge;
  }

  protected async getBadgeMembers(badgeName: string): Promise<MemberData[]> {

    const allMembers = await lastValueFrom(this.#adcmRepo.getAllMemberInfo());

    return allMembers.filter(member => !member.archived && member.additionalBadges.find(badge => badge.badge.toLowerCase() === badgeName.toLowerCase()));
  }

}
