export enum EBadge {
  CIB1 = 'CIB1',
  CIB2 = 'CIB2',
  CIB3 = 'CIB3',
  CIB4 = 'CIB4',
  ZEUS_PERMANENT = 'ZEUS_PERMANENT',
  ZEUS_ROTATION = 'ZEUS_ROTATION',
  COUNCIL = 'COUNCIL',
  INSTRUCTOR = 'INSTRUCTOR',
  INSTRUCTOR_SENIOR = 'INSTRUCTOR_SENIOR',
  RECRUITER = 'RECRUITER',
  RECRUITER_SENIOR = 'RECRUITER_SENIOR',
  MISSION = 'MISSION',
  MISSION_SENIOR = 'MISSION_SENIOR',
  IT = 'IT',
  IT_SENIOR = 'IT_SENIOR',
  ADDONS = 'ADDONS',
  ADDONS_SENIOR = 'ADDONS_SENIOR',
  PR = 'PR',
  PR_SENIOR = 'PR_SENIOR'
}
