import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {UrlService} from "../../service/url-service/url.service";
import {MemberData} from "../profile-page/config/member-data";
import {SEOService} from "../../service/seo-service/seo.service";
import {RibbonStatistic} from "../../types/ribbon-statistic";

import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {LoadingComponent} from "../../shared/loading/loading.component";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";

@Component({
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgOptimizedImage,
    NgForOf,
    LoadingComponent,
    MainLayoutComponent,
    AirCardComponent
  ],
  selector: 'app-ribbon-page',
  templateUrl: './ribbon-page.component.html',
  styleUrls: ['./ribbon-page.component.scss']
})
export class RibbonPageComponent implements OnInit {

  isLoading = true;

  ribbonData: RibbonStatistic;

  constructor(
    protected route: ActivatedRoute,
    protected urlService: UrlService,
    protected router: Router,
    protected seoService: SEOService,
  ) { }

  ngOnInit(): void {

    this.urlService.setPreviousUrl(this.router.url);

    this.ribbonData = this.route.snapshot.data['ribbonData'];

    this.seoService.setPageTitle(this.ribbonData.ribbon.name);
    this.seoService.updateKeyWords(['ribbon', 'abzeichen'])

    this.isLoading = false;
  }


  getRankIconPath(member: MemberData) {

    if (member.zeusType === 'PERMANENT') {
      return 'assets/images/ranks/MSG.svg';
    }

    return 'assets/images/ranks/' + member.rankIdentCode + '.svg';
  }

}
