import {Component, inject, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ThemeService} from '../../service/theme-service/theme.service';
import {AirborneTranslationService} from '../../service/airborne-translation-service/airborne-translation.service';
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {TooltipModule} from "primeng/tooltip";
import {ProgressBarModule} from "primeng/progressbar";

@Component({
  standalone: true,
  imports: [
    NgClass,
    TooltipModule,
    NgIf,
    NgOptimizedImage,
    ProgressBarModule
  ],
  selector: 'air-lu-item-paragraph',
  templateUrl: './line-up-item-paragraph.component.html',
  styleUrls: ['./line-up-item-paragraph.component.scss']
})
export class LineUpItemParagraphComponent {

  readonly #router = inject(Router);
  readonly #themeService = inject(ThemeService);
  readonly #airborneTranslationService = inject(AirborneTranslationService);

  @Input() title: string;
  @Input() text: string;
  @Input() rankName: string;
  @Input() disableLink: boolean = false;
  @Input() isJoinUsLink: boolean = false;
  @Input() tooltip: string = '';
  @Input() hasNarrowItems: boolean = false;
  @Input() id: number | undefined;

  isProfileLoading: boolean = false;

  ngOnInit(): void {}

  handleProfileClick() {
    if (!this.isProfileLoading && !this.disableLink) {
      this.isProfileLoading = true;
      this.#router.navigate(['/profile', this.text.toLowerCase()]);
    }
  }

  isDarkTheme() {
    return this.#themeService.getCurrentThemeNameShort() === 'dark';
  }

  getRankIcon() {
    if (this.rankName === 'ZEUS') {
      return 'assets/images/ranks/ZEUS.png';
    }

    return 'assets/images/ranks/' + this.rankName + '.svg';
  }

  getRankTranslation(rank: string) {
    return this.#airborneTranslationService.getRanksTranslation(rank);
  }
}
