import LineUpPosition from './lineup-position.type';

export class LineUpGroup {
  callSign: string;
  id: number;
  shortName: string;
  name: string;
  positions: LineUpPosition[];
  children: LineUpGroup[];
}

export default LineUpGroup;
