<air-loading [isLoading]="isLoading"></air-loading>

<air-main-layout *ngIf="!isLoading">
  <div class="grid w-full">
    <div class="col">
      <div class="badge">
        <div class="header flex justify-content-between">
          <div class="ml-2">
            {{ getBadgeTranslation(badgeData.badge.badge).name }}
          </div>
          <div class="cursor-pointer close-button" [routerLink]="['/', 'statistics']">
            <i class="pi pi-times"></i>
          </div>
        </div>
        <div class="content">
          <div class="col-12 flex flex-column profile-grid left">
            <air-card class="air-line-up-card">

              <div  class="flex w-full gap-3 p-2">
                <div class="text-center pl-2">
                  <img
                    width="104"
                    height="104"
                    [ngSrc]="getManagementBadgePath(badgeData.badge)"
                    [alt]="badgeData.badge.comment"
                    [priority]="true"
                  >
                </div>
                <div class="info flex flex-column justify-content-center">
                  <div class="font-bold">
                    <span>{{ getBadgeTranslation(badgeData.badge.badge).description }}</span><br>
                    <span>{{ badgeData.members.length }} Mal vergeben</span>
                  </div>
                </div>
              </div>
            </air-card>

            <air-card class="air-line-up-card">
              <h2>Mitglieder</h2>

              <div class="flex flex-wrap">
                <div [routerLink]="['/', 'profile', member.name]" class="flex w-12 md:w-3 p-2" *ngFor="let member of badgeData.members">
                  <div class="info flex justify-content-between w-full ribbon-or-badges-link p-2">
                    <div class="font-bold pr-2 flex align-items-center">
                      <span style="word-break: break-all;" class="text-lg">{{ member.name }}</span><br>
                    </div>
                    <div class="w-4 text-center">
                      <img
                        [ngSrc]="getRankIconPath(member)"
                        [alt]="member.rankIdentCode"
                        style="min-height: 36px; max-width: 36px; min-width: 36px; max-height: 50px;"
                        width="117"
                        height="135"
                      />
                    </div>
                  </div>
                </div>
              </div>

            </air-card>

          </div>

        </div>
      </div>
    </div>
  </div>
</air-main-layout>
