<air-main-layout>
  <div class="grid w-full">
    <div class="col">
      <air-card>
        <div class="grid">
          <div class="lg:col-8 md:col-12">
            <div class="col-12 text-center">
              <img
                class="image"
                ngSrc="../assets/icons/discord.svg"
                alt="Discord Logo"
                width="600"
                height="100"
                [priority]="true"
              >
            </div>
            <div class="col-12 text-justify">
              <p>
                Wir bieten jedem neugierigen Interessenten die Möglichkeit mit uns über Discord zu kommunizieren und einen
                ersten Einblick in unser Clanleben zu erhalten. Discord ist ein hervorragendes Programm um sich auf die
                Schnelle auszutauschen. Abgesehen vom Textchat, beinhaltet Discord auch eine Möglichkeit mit uns per Sprache
                zu kommunizieren. In der Regel sind immer ein paar feste Mitglieder des Clans online und stehen mit Rat und
                Tat zur Seite und beantworten gerne jede Frage oder sind zum plaudern bereit. Es ist möglich, sich über den
                Webclienten von Discord auf unseren Server zu verbinden. Es ist keine Anmeldung vonnöten. Einfach den
                gewünschten Nicknamen eingeben und los geht’s.
              </p>

              <p>
                Falls du dich mit Discord nicht auskennst und den Clienten herunterladen möchtet, so kannst du hier die
                Webseite des Herstellers aufsuchen. Zusätzlich gibt es dort eine Einführung und eine Auflistung sämtlicher
                Features, die das Programm bietet.
              </p>

              <p>
                Unter folgendem Link kannst du unserem Server beitreten: <a class="alternate-text-link" href="https://discord.gg/WrKtH28" target="_blank">https://discord.gg/WrKtH28</a>. Dieser Link führt direkt
                auf unseren Server über deinen Browser – du benötigst nicht den Clienten. Du kannst diesen Link aber auch in
                den Clienten von Discord eingeben und dort unserem Server beitreten.
              </p>

              <span class="flex justify-content-center mt-2">
                <air-button link="https://discordapp.com/" [openInNewTab]="true">
                  Webseite des Herstellers
                </air-button>

                <air-button class="ml-5" link="https://discord.gg/WrKtH28" [openInNewTab]="true">
                 Discord verbinden
                </air-button>
              </span>

            </div>
          </div>
        <div class="lg:col-4 md:col-12">

          <air-external-content name="Discord">
            <iframe
              [src]="getDiscordWidgetSource()"
              width="100%"
              height="560"
              allowtransparency="true"
              frameborder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe>
          </air-external-content>

        </div>
      </div>
      </air-card>
    </div>
  </div>

  <div class="w-full text-center mt-2 mb-2 opacity-20">
    <img
      ngSrc="./assets/icons/US_101st_Airborne_Division_patch-1.svg"
      height="200"
      width="200"
      alt="division patch logo"
    />
  </div>
</air-main-layout>
