export class Translation {
  [key: string]: TranslationValue;
}

export class TranslationValue {
  name: string;
  description?: string;
}

export default Translation;
