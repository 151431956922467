import {Component, OnInit} from '@angular/core';
import {ExternalContentService} from '../../service/external-content-service/external-content.service';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {SEOService} from '../../service/seo-service/seo.service';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

import {ChipModule} from "primeng/chip";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {AirButtonComponent} from "../../shared/air-button/air-button.component";

@Component({
  standalone: true,
  imports: [
    ChipModule,
    NgForOf,
    NgIf,
    NgOptimizedImage,
    MainLayoutComponent,
    AirCardComponent,
    FontAwesomeModule,
    AirButtonComponent
  ],
  selector: 'air-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss']
})
export class PrivacyPageComponent implements OnInit {

  currentAllowedExternalContent: string[];

  faChevronRight = faChevronRight as IconDefinition;

  constructor(
    public externalContentService: ExternalContentService,
    protected seoService: SEOService,
    ) {
  }

  ngOnInit(): void {

    this.seoService.setPageTitle('Datenschutz');
    this.seoService.updateKeyWords(['datenschutz', 'privacy'])
    this.setAllowedExternalContent();
  }

  setAllowedExternalContent() {
    if (!this.isAllExternalContentAllowed() && this.externalContentService.isAnyExternalContentAllowed()) {
      this.currentAllowedExternalContent = this.externalContentService.getAllowedExternalContent();
    }
  }

  allowAllExternalContent() {
    this.externalContentService.allowAllExternalContent();
    this.currentAllowedExternalContent = [];
  }

  removeAllExternalContentPermissions() {
    this.externalContentService.removeAllPermissions();
    this.currentAllowedExternalContent = [];
  }

  isAllExternalContentAllowed() {
    return this.externalContentService.isAllExternalContentAllowed();
  }

  isAnyExternalContentAllowed() {
    return this.externalContentService.isAnyExternalContentAllowed();
  }

  removeExternalSourceFromList(name: string) {
    this.externalContentService.removePermission(name);
    this.currentAllowedExternalContent = this.currentAllowedExternalContent.filter(item => item !== name)
  }
}
