<air-main-layout>
  <div class="grid w-full">
    <div class="col">
      <air-card>
        <h1 class="text-center air-card-title mb-0">Organisation</h1>
        <div class="grid mt-2">
          <div class="col-12 md:col-6 air-dual-column-left">
            <p class="text-justify">
              Traditionell ist unser Clan in mehrere Abteilungen aufgeteilt.
              Konzeptionell hat jeder Bereich seine Aufgaben und ein Stimmrecht,
              wenn es um die Ausrichtung des Clans geht. Die Clanleitung spielt
              hier nur eine untergeordnete Rolle, darf aber vom Vetorecht
              Gebrauch machen. So hat jedes aktive Mitglied Einfluss auf den
              Clan. Das ist so gewünscht. Wir betrachten jedes Mitglied als
              wertvoll für den Clan und wünschen uns auch eine rege Beteiligung
              an Abstimmungen. Die Erklärung zu unseren Abteilungen kann auf
              unserer “Wer wir sind”-Seite eingesehen werden.
            </p>
            <p class="text-justify">
              Zu erreichen sind alle Abteilungen immer über Discord oder im
              Forum.
            </p>
          </div>
          <div class="col-12 md:col-6 air-dual-column-right">
            <img
              class="air-image"
              ngSrc="../../../../assets/images/Main_composition_00126.jpg"
              alt="Große Besprechung während einer Mission"
              height="355"
              width="850"
              [priority]="true"
            />
          </div>
        </div>
        <span class="flex justify-content-center mt-2 .air-departments-buttons">
          <air-button class="mr-1" [link]="paths.home">
            Wer wir sind
          </air-button>
          <air-button class="mr-1" [link]="paths.discord"> Discord </air-button>
          <air-button [link]="paths.forum"> Forum </air-button>
        </span>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col-12 md:col-6 air-outer-dual-column-left">
      <air-department-card
        title="Leitung"
        description="Die Clanleitung übt den verwaltenden Akt aus und steht für alle Anfragen offen, die übergeordnet die Einheit betreffen."
        [departmentData]="departments.management"
      ></air-department-card>
    </div>

    <div class="col-12 md:col-6 air-outer-dual-column-right">
      <air-department-card
        title="Ausbildung"
        description="Unsere Ausbilder kümmern sich um die Durchführung unserer wöchentlichen Trainingsmodule."
        [departmentData]="departments.trainer"
      ></air-department-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col-12 md:col-6 air-outer-dual-column-left">
      <air-department-card
        title="Missionsbau"
        description="Jede Woche bescheren uns die fleißigen Missionsbauer einen ansprechenden Einsatz. Die Ersteller der Mission stehen des Weiteren für Fragen und Vorschläge zur Verfügung. Auch für eine Einführung in den Missionsbau gibt es hier Hilfe."
        [departmentData]="departments.missionbuilder"
      ></air-department-card>
    </div>

    <div class="col-12 md:col-6 air-outer-dual-column-right">
      <air-department-card
        title="Recruiting"
        description="Die Rekrutierer sind sowohl für die Antworten auf Bewerbungen zuständig, als auch für Bewerbungsgespräche selbst."
        [departmentData]="departments.recruiter"
      ></air-department-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col-12 md:col-6 air-outer-dual-column-left">
      <air-department-card
        title="PR und Events"
        description="Unser Eventteam kümmert sich um die Planung und Durchführung der Events. Volli schreibt unseren Blog und unseren Twitter-Feed."
        [departmentData]="departments.events"
      ></air-department-card>
    </div>

    <div class="col-12 md:col-6 air-outer-dual-column-right">
      <air-department-card
        title="Technik"
        description="In der Technikabteilung stehen die Mitglieder unserer Einheit in den jeweiligen
        Bereichen immer für Fragen bereit. Hier wird sich um die Webseite, Rootserver,
        Spielserver, Forum und TeamSpeak gekümmert."
        [departmentData]="departments.engineering"
      ></air-department-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col-12 md:col-6 air-outer-dual-column-left">
      <air-department-card
        title="Vertrauenspersonen"
        [departmentData]="departments.council"
      ></air-department-card>
    </div>

    <div class="col-12 md:col-6 air-outer-dual-column-right"></div>
  </div>
</air-main-layout>
