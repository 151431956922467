<air-loading [isLoading]="isLoading"></air-loading>

<air-main-layout *ngIf="!isLoading">
  <div class="grid w-full">
    <div class="col">
      <div class="profile">
        <div class="header flex justify-content-between">
          <div class="ml-2">
            {{ memberData.name }}
          </div>
          <div class="cursor-pointer close-button" [routerLink]="origin">
            <i class="pi pi-times"></i>
          </div>
        </div>
        <div class="content">
          <div class="col-12 lg:col-4 flex flex-column profile-grid left">
            <air-card class="air-line-up-card">
              <img
                [ngSrc]="getUniformPath()"
                [alt]="'Die Uniform von ' + memberData.name"
                class="w-full h-full"
                width="100"
                height="100"
                [pTooltip]="'Die Uniform von ' + memberData.name"
                [priority]="true"
              />
            </air-card>

            <air-card
              class="air-line-up-card"
              [headerTitle]="'Zusätzliche Abzeichen'"
            >
              <div
                class="flex gap-20"
                *ngFor="let badge of badges.additionalBadges"
              >
                <div class="text-center">
                  <img
                    class="air-profile-badge"
                    [ngSrc]="getAdditionalBadgePath(badge)"
                    [alt]="getAdditionalBadgeTranslation(badge).description"
                    [pTooltip]="getAdditionalBadgeTranslation(badge).description || ''"
                    width="100"
                    height="100"
                  />
                </div>
                <div class="info flex flex-column justify-content-center">
                  <div class="font-bold">
                    {{ getAdditionalBadgeTranslation(badge).name }}
                  </div>
                  <div>
                    verliehen am {{ badge.assignDate | date: 'dd.MM.YYYY' }}
                  </div>
                </div>
              </div>

              <div
                class="text-center color-gray"
                *ngIf="badges.additionalBadges?.length === 0"
              >
                Noch keine Abzeichen erhalten
              </div>
            </air-card>
          </div>

          <div class="col-12 lg:col-4 flex flex-column profile-grid">
            <air-card class="air-line-up-card">
              <div class="flex">
                <div class="text-center w-6">
                  <img
                    ngSrc="assets/images/company-flag.png"
                    alt="Die Flagge unserer Company. Zu sehen ist das Abzeichen der dritten Brigade der 101st Airborne Division (Rakkansan). Darunter zu sehen sind Elemente der Regimentswappen der Teileinheiten unserer Company. Der weiße Blitz für das 506 Infantry Regiment und das weiße Quadrat auf grünem Grund für das 33 Cavalry Regiment. Es handelt sich bei dem Logo um eine Eigenkreation."
                    pTooltip="Die Flagge unserer Company. Zu sehen ist das Abzeichen der dritten Brigade der 101st Airborne Division (Rakkansan). Darunter zu sehen sind Elemente der Regimentswappen der Teileinheiten unserer Company. Der weiße Blitz für das 506 Infantry Regiment und das weiße Quadrat auf grünem Grund für das 33 Cavalry Regiment. Es handelt sich bei dem Logo um eine Eigenkreation."
                    height="136"
                    width="122"
                  />
                </div>
                <div class="info flex flex-column w-6">
                  <img
                    ngSrc="assets/images/US_101st_Airborne_Division_patch-1.svg"
                    height="140"
                    width="140"
                    alt="Das Abzeichen der 101st Airborne Division"
                    pTooltip="Das Abzeichen der 101st Airborne Division"
                  />
                </div>
              </div>
            </air-card>

            <air-card class="air-line-up-card" [headerTitle]="'Informationen'">
              <div class="flex justify-content-between">
                <ul class="member-info">
                  <li>
                    <div class="flex">
                      <span class="indicator">Name</span>
                      <span class="value">{{ memberData.name }}</span>
                    </div>
                  </li>
                  <li *ngIf="isMemberActive()">
                    <div class="flex" [pTooltip]="getStripeDescription()">
                      <span class="indicator">Mitglied seit</span>
                      <span class="value">{{
                        memberData.joinDate | date: 'dd.MM.YYYY'
                      }}</span>
                    </div>
                  </li>
                  <li *ngIf="isMemberActive()">
                    <div class="flex align-items-center">
                      <span class="indicator">Rang</span>
                      <span class="value">{{ getRankTranslation().name }}</span>
                    </div>
                  </li>
                  <li *ngIf="isMemberActive()">
                    <div class="flex">
                      <span class="indicator">Position</span>
                      <span class="value">{{ getPositionTranslation().name }}</span>
                    </div>
                  </li>
                  <li *ngIf="!isMemberActive()" class="font-bold">
                    Dieses Mitglied ist nicht mehr aktiv.
                  </li>
                </ul>
                <div
                  class="flex justify-content-center align-items-center member-rank relative"
                  [pTooltip]="getRankTranslation().name"
                >
                  <img
                    [ngSrc]="getRankIconPath()"
                    [alt]="memberData.rankIdentCode"
                    class="rank-icon"
                    style="object-fit: contain;"
                    fill
                  />
                </div>
              </div>
            </air-card>

            <air-card
              class="air-line-up-card"
              headerTitle="Social Media"
              *ngIf="memberData.twitchUrl || memberData.youtubeUrl"
            >
              <div class="flex flex-column">
                <a
                  class="profile-social-media-button twitch"
                  target="_blank"
                  [href]="memberData.twitchUrl"
                  *ngIf="memberData.twitchUrl"
                >
                  <fa-icon class="mr-2" [icon]="faTwitch"></fa-icon>
                  Twitch
                </a>
                <a
                  class="profile-social-media-button youtube"
                  target="_blank"
                  [href]="memberData.youtubeUrl"
                  *ngIf="memberData.youtubeUrl"
                >
                  <fa-icon class="mr-2" [icon]="faYoutube"></fa-icon>
                  Youtube
                </a>
              </div>
            </air-card>

            <air-card
              class="air-line-up-card"
              [headerTitle]="'Management Abzeichen'"
            >
              <div
                class="flex gap-20"
                *ngFor="let badge of badges.managementBadges"
              >
                <div class="text-center">
                  <img
                    class="air-profile-badge"
                    [ngSrc]="getManagementBadgePath(badge)"
                    [alt]="getManagementBadgeTranslation(badge).description"
                    [pTooltip]="getManagementBadgeTranslation(badge).description || ''"
                    width="100"
                    height="100"
                  />
                </div>
                <div class="info flex flex-column justify-content-center">
                  <div class="font-bold">
                    {{ getManagementBadgeTranslation(badge).name }}
                  </div>
                  <div>
                    verliehen am {{ badge.assignDate | date: 'dd.MM.YYYY' }}
                  </div>
                </div>
              </div>

              <div
                class="text-center color-gray mb-1"
                *ngIf="badges.managementBadges?.length === 0"
              >
                Noch keine Management-Abzeichen erhalten
              </div>
            </air-card>
          </div>

          <div class="col-12 lg:col-4 flex flex-column profile-grid right">
            <air-card
              class="air-line-up-card"
              [headerTitle]="'Combat Infantryman Abzeichen'"
            >
              <div
                class="flex gap-20"
                *ngFor="let badge of badges.combatInfantrymanBadges"
              >
                <div class="text-center">
                  <img
                    [ngSrc]="getCombatInfantryManBadgePath(badge)"
                    width="120"
                    height="60"
                    [alt]="getCombatInfantryManBadgeTranslation(badge).description"
                    [pTooltip]="getCombatInfantryManBadgeTranslation(badge).description ||''"
                  />
                </div>
                <div class="info flex flex-column justify-content-center">
                  <div class="font-bold">
                    {{ getCombatInfantryManBadgeTranslation(badge).name }}
                  </div>
                </div>
              </div>

              <div
                class="text-center color-gray mb-1"
                *ngIf="badges.combatInfantrymanBadges?.length === 0"
              >
                Noch kein Combat Infantryman-Abzeichen erhalten
              </div>
            </air-card>

            <air-card
              class="air-line-up-card"
              [headerTitle]="'Einsatz-Ribbons'"
            >
              <div class="mb-1">
                <p-accordion [expandIcon]="''" [collapseIcon]="''">
                  <p-accordionTab
                    class="flex"
                    *ngFor="let badge of getSortedRibbons()"
                  >
                    <ng-template pTemplate="header">
                      <div class="flex w-full gap-20">
                        <div class="text-center">
                          <img
                            [ngSrc]="badge.imageURL"
                            width="80"
                            height="70"
                            [alt]="badge.description"
                          />
                        </div>
                        <div
                          class="info flex flex-column justify-content-center"
                        >
                          <div class="font-bold">{{ badge.name }}</div>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="content">{{
                      badge.description
                    }}</ng-template>
                  </p-accordionTab>
                </p-accordion>
              </div>

              <div
                class="text-center color-gray mb-1"
                *ngIf="memberData.ribbons?.length === 0"
              >
                Noch keine Ribbons erhalten
              </div>
            </air-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</air-main-layout>
