<div class='menu'>

  <section>
    <a class='logo' routerLink='/'>
      <img
        [ngSrc]='config.icon.path'
        width="50"
        height="50"
        [alt]='config.icon.alt'
        [priority]='true'
      >
    </a>
  </section>

  <section>

    <nav>
      <span
        *ngFor='let item of config.items'
        [class.subnav]='item.subMenu'
        (click)='item.subMenu && openSubMenu(item.label)'
      >
        <a *ngIf='!item.subMenu && item.isExternal' [href]='item.targetUrl' class='item' target='_blank' rel="noreferrer">{{item.label}}</a>
        <a *ngIf='!item.subMenu && !item.isExternal' [routerLink]='item.targetUrl' [routerLinkActive]="'active'" class='item'>{{item.label}}</a>

        <button *ngIf='item.subMenu' class='subnav-button'>
          {{item.label}}
          <fa-icon *ngIf="item.subMenuIconClosed && currentOpenSubMenu !== item.label" [icon]="item.subMenuIconClosed"></fa-icon>
          <fa-icon *ngIf="item.subMenuIconOpen && currentOpenSubMenu === item.label" [icon]="item.subMenuIconOpen"></fa-icon>
        </button>
        <ng-container *ngIf='item.subMenu && currentOpenSubMenu === item.label'>
          <div class='subnav-background'></div>
          <div class='subnav-content'>

            <ng-container *ngFor='let subMenuItem of item.subMenu'>
              <a *ngIf='subMenuItem.isExternal' [href]='subMenuItem.targetUrl' class='item' target='_blank' rel="noreferrer">{{subMenuItem.label}}</a>
              <a *ngIf='!subMenuItem.isExternal' [routerLink]='subMenuItem.targetUrl' [routerLinkActive]="'active'" class='item'>{{subMenuItem.label}}</a>
            </ng-container>

          </div>
        </ng-container>
      </span>
    </nav>

  </section>

</div>
