import {HeaderMenuItem} from '../config/header-menu-item';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {HeaderMenuIcon} from '../config/header-menu-icon';
import {Injectable} from '@angular/core';
import {HeaderMenu} from '../config/header-menu';

@Injectable()
export class HeaderMenuBuilder {

  protected items: HeaderMenuItem[] = [];
  protected icon: HeaderMenuIcon;

  addIcon(pathToIcon: string, alternateText: string): this {

    const icon = new HeaderMenuIcon();
    icon.path = pathToIcon;
    icon.alt = alternateText;

    this.icon = icon;

    return this;
  }

  addItem(label: string, targetUrl: string, isExternal?: boolean): this {

    const item = new HeaderMenuItem();
    item.label = label;
    item.targetUrl = targetUrl;
    item.isExternal = isExternal;

    this.items.push(item);

    return this;
  }

  addItemWithSubmenu(label: string, subMenuIconOpen: IconDefinition, subMenuIconClosed: IconDefinition): this {

    const item = new HeaderMenuItem();
    item.label = label;
    item.subMenu = [];
    item.subMenuIconOpen = subMenuIconOpen;
    item.subMenuIconClosed = subMenuIconClosed;

    this.items.push(item);

    return this;
  }

  addItemToSubmenu(label: string, targetUrl: string, isExternal?: boolean): this {

    const item = new HeaderMenuItem();
    item.label = label;
    item.targetUrl = targetUrl;
    item.isExternal = isExternal;

    const lastItem = this.getLastItem();
    lastItem.subMenu?.push(item);

    return this;
  }

  clear(): this {

    this.items = [];

    return this;
  }

  buildConfig(): HeaderMenu {

    const config = new HeaderMenu();
    config.icon = this.icon;
    config.items = this.items;

    return config;
  }

  protected getLastItem() {

    if (Array.isArray(this.items) && this.items.length > 0) {
      return this.items[this.items.length - 1];
    }

    throw new Error('Add an item first');
  }
}
