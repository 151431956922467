import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { LocalstorageService } from '../localstorage-service/localstorage.service';
import { ThemeDetails } from './config/theme-details';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {Observable, Subject} from "rxjs";

@Injectable()
export class ThemeService {

  themeChangedSubject = new Subject<string>();

  protected renderer: Renderer2;
  protected themes: ThemeDetails[] = [];

  constructor(
    protected rendererFactory: RendererFactory2,
    protected localStorageService: LocalstorageService,
    @Inject(DOCUMENT) protected document: Document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  addTheme(name: string, icon: IconDefinition): this {

    const theme = new ThemeDetails();
    theme.name = name;
    theme.icon = icon;

    this.themes.push(theme);

    return this;
  }

  getThemeList() {
    return this.themes;
  }

  setDefaultBrowserTheme() {

    if (
      this.getCurrentThemeName() &&
      this.themes.find((theme) => theme.name === this.getCurrentThemeName())
    ) {
      this.setTheme(this.getCurrentThemeName()).subscribe();
    } else if (this.getDefaultBrowserTheme() === 'dark') {
      this.setTheme('theme-dark').subscribe();
    } else {
      this.setTheme('theme-light').subscribe();
    }
  }

  getCurrentThemeName(): string {
    return this.localStorageService.getItem('currentTheme') || '';
  }

  getCurrentThemeNameShort() {

    return this.getCurrentThemeName()
      ?.toLocaleLowerCase()
      .replace('theme-', '');
  }

  setTheme(themeName: string): Observable<string> { // TODO rh themeName -> enum

    return new Observable(subscriber => {

      if (isPlatformBrowser(this.platformId)) {
        this.renderer.setAttribute(this.document.body, 'class', themeName);
        this.localStorageService.setItem('currentTheme', themeName);

        this.themeChangedSubject.next(themeName);

        subscriber.next(themeName);
      }
    })

  }

  protected getDefaultBrowserTheme() {

    if (
      isPlatformBrowser(this.platformId) &&
      window?.matchMedia?.('(prefers-color-scheme: dark)').matches
    ) {
      return 'dark';
    }

    return 'light';
  }

  build() {
    this.setDefaultBrowserTheme();
  }
}
