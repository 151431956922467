import { Component, OnInit } from '@angular/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import paths from 'src/paths';
import packageJson from '../../../../../package.json';
import {SEOService} from '../../service/seo-service/seo.service';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

import {RouterLink} from "@angular/router";
import {NgOptimizedImage} from "@angular/common";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@Component({
  standalone: true,
  imports: [
    RouterLink,
    NgOptimizedImage,
    MainLayoutComponent,
    AirCardComponent,
    FontAwesomeModule
  ],
  selector: 'app-imprint-page',
  templateUrl: './imprint-page.component.html',
  styleUrls: ['./imprint-page.component.scss']
})
export class ImprintPageComponent implements OnInit {
  get paths() {
    return paths;
  }

  faHeart = faHeart as IconDefinition;

  get version() {
    return packageJson.version;
  }

  constructor(protected seoService: SEOService) {}

  ngOnInit(): void {

    this.seoService.setPageTitle('Impressum');
    this.seoService.updateKeyWords(['impressum', 'imprint'])
  }
}
