import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class SEOService {

  protected renderer: Renderer2;
  protected defaultKeywords: string[] = [];
  protected mainTitle: string;

  constructor(
    protected title: Title,
    protected metaService: Meta,
    protected rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setMainTitle(title: string) {
    this.mainTitle = title;
  }

  getMainTitle() {
    return this.mainTitle;
  }

  setPageTitle(title: string) {
    this.title.setTitle(title + ' | ' + this.getMainTitle());
  }

  getPageTitle() {
    return this.title.getTitle();
  }

  updateTag(tag: MetaDefinition) {
      this.metaService.updateTag(tag);
  }

  updateKeyWords(keywords: string[]) {

    const keywordsTag = {} as MetaDefinition;
    keywordsTag.name = 'keywords';
    keywordsTag.content = this.defaultKeywords.join(', ') + ', ' + keywords.join(', ');

    this.metaService.updateTag(keywordsTag);
  }

  setDefaultKeywords(keywords: string[]) {
    this.defaultKeywords = keywords;
  }

  getDefaultKeywords() {
    return this.defaultKeywords;
  }
}
