import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class LocalstorageService implements Storage {

  length: number;
  protected storage: Storage | undefined;

  constructor(
    @Inject(PLATFORM_ID) protected platformId: Object,
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.storage = localStorage;
    } else {
      this.storage = undefined;
    }
  }

  clear(): void {
    this.storage?.clear();
  }

  getItem(key: string): string | null {
    return this.storage?.getItem(key) || null;
  }

  key(index: number): string | null {
    return this.storage?.key(index) || null;
  }

  removeItem(key: string): void {
    return this.storage?.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this.storage?.setItem(key, value);
  }
}
