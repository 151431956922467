<air-main-layout>
  <div class="grid w-full">
    <div class="col">
      <air-card>
        <h1 class="text-center air-card-title mb-0">Das Mentorensystem</h1>
        <div class="grid mt-2">
          <div class="col-12 md:col-6 air-dual-column-left flex flex-column justify-content-between">
            <p class="text-justify">
              Als Rekrut in der 101st Airborne Division wirst du natürlich nicht
              dir selbst überlassen. Du bekommst von uns einen Mentor
              zugewiesen, der neben den Ausbildungsverantwortlichen stets als
              Ansprechpartner für dich zur Verfügung steht. Des Weiteren kümmert
              sich dein Mentor darum, dass du in der Lage bist auf unseren
              Server zu verbinden und erleichtert dir den Start bei uns mit
              einem circa einstündigen Trainingsmodul. Danach findet deine
              weitere Grundausbildung in unseren wöchentlichen Trainings statt.
            </p>

            <span class="flex justify-content-center mt-2">
              <air-button [link]="paths.ausbildung.children.mentorenliste.full">
                Zur Liste der Mentorenschulungen
              </air-button>
            </span>
          </div>
          <div class="col-12 md:col-6 air-dual-column-right">
            <img
              class="air-image"
              ngSrc="../../../../assets/images/Main_composition_00817.jpg"
              alt="Besprechung während einer Mission"
              height="478"
              width="850"
              [priority]="true"
            />
          </div>
        </div>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col">
      <air-card headerTitle="Teilnahme an Missionen ohne Training">
        <p class="text-justify mb-0">
          Du kannst an Missionen teilnehmen, ohne vorher ein Training gemacht zu
          haben. Ein Mentorentraining ist jedoch vorher Pflicht.
        </p>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col">
      <air-card headerTitle="Trainingsinhalte">
        <p-table
          [value]="items"
          styleClass="p-datatable-sm"
          responsiveLayout="scroll"
        >
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{ item.title }}</td>
              <td>{{ item.text }}</td>
            </tr>
          </ng-template>
        </p-table>
      </air-card>
    </div>
  </div>

  <div class="grid w-full">
    <div class="col">
      <air-card headerTitle="Die Trainingsmodule">
        <div class="grid">
          <div class="col-12 md:col-6 air-dual-column-left">
            <img
              class="air-image"
              ngSrc="../../../../assets/images/Main_composition_00709_1.jpg"
              alt="Eine Schulung während des Trainings"
              height="478"
              width="850"
              [priority]="true"
            />
          </div>
          <div class="col-12 md:col-6 air-dual-column-right">
            <p class="text-justify">
              Nachdem dein Mentor dich in die absoluten Grundzüge unserer
              Lerninhalte eingewiesen hat, wird deine Ausbildung in fünf
              weiteren Modulen vertieft. Das Training findet wöchentlich statt
              und sobald ein Trainingszyklus beendet wurde beginnt ein neuer.
              Für dich als Rekrut ist es jedoch unerheblich, zu welchem
              Zeitpunkt du in den Zyklus einsteigst, da die Module inhaltlich
              abgeschlossen sind. Nachdem du jedes Modul einmal besucht hast,
              wirst du zu einer abschließenden Prüfung zugelassen, die das Ende
              deiner Grundausbildung darstellt.
            </p>
          </div>
        </div>

        <span class="flex justify-content-center mt-2">
          <air-button [link]="paths.ausbildung.children.module.full">
            Zu den Trainingsmodulen
          </air-button>
        </span>
      </air-card>
    </div>
  </div>
</air-main-layout>
