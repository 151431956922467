<air-card [headerTitle]="title" [centerHeaderTitle]="true">
  <ng-container *ngFor="let leadTeam of squadData?.positions">
    <air-lu-item-paragraph
      *ngIf="leadTeam.member"
      [title]="leadTeam?.name || ''"
      [text]="leadTeam?.member?.name || ''"
      [rankName]="leadTeam?.member?.rankIdentCode || ''"
      [id]="leadTeam?.member?.id"
    ></air-lu-item-paragraph>
    <air-lu-item-paragraph
      *ngIf="!leadTeam.member"
      [title]="leadTeam.name"
      [isJoinUsLink]="true"
      text="Jetzt bewerben!"
    ></air-lu-item-paragraph>
  </ng-container>

  <ng-container *ngFor="let team of squadData?.children">
    <air-line-up-card-header [isAlternateHeader]="true">
      {{ team.name }}
    </air-line-up-card-header>
    <ul class="air-lu-item-list">
      <ng-container *ngFor="let person of team.positions">
        <air-lu-item-paragraph
          *ngIf="person.member"
          [title]="person.name"
          [text]="person.member.name"
          [rankName]="person.member.rankIdentCode"
          [id]="person.member.id"
        ></air-lu-item-paragraph>
        <air-lu-item-paragraph
          *ngIf="!person.member"
          [title]="person.name"
          [isJoinUsLink]="true"
          text="Jetzt bewerben!"
        ></air-lu-item-paragraph>
      </ng-container>
    </ul>
  </ng-container>
</air-card>
