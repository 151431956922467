export enum EPosition {
  PMED = 'PMED',
  CO = 'CO',
  '1SG' = '1SG',
  PL = 'PL',
  PSG = 'PSG',
  RTO = 'RTO',
  SL = 'SL',
  TL = 'TL',
  AR = 'AR',
  GR = 'GR',
  RM = 'RM',
  DMR = 'DMR',
  MG = 'MG',
  AG = 'AG',
  AB = 'AB',
  AT = 'AT',
  AH = 'AH',
  MED = 'MED',
  CMED = 'CMED',
  SCL = 'SCL',
  OPSGT = 'OPSGT',
}
