import {Component, Input} from '@angular/core';
import LineUpGroup from '../../types/lineup-group.type';

import {NgClass, NgForOf, NgIf} from "@angular/common";
import {LineUpItemParagraphComponent} from "../line-up-item-paragraph/line-up-item-paragraph.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";

@Component({
  standalone: true,
  imports: [
    NgClass,
    LineUpItemParagraphComponent,
    NgIf,
    NgForOf,
    AirCardComponent
  ],
  selector: 'air-line-up-leader-card',
  templateUrl: './line-up-leader-card.component.html',
  styleUrls: ['./line-up-leader-card.component.scss']
})
export class LineUpLeaderCardComponent {
  @Input() title: string;
  @Input() lineUpData: LineUpGroup | null;
  @Input() leaderPosition: string;
  @Input() secondLinePositions: string[] = [];
  @Input() thirdLinePositions: string[] = [];
  @Input() hasNarrowItems: boolean = false;
  @Input() id: number | undefined;

  get leaderObj() {
    return this.lineUpData?.positions.find(
      (elem) => elem.name == this.leaderPosition
    );
  }

  get allPositions() {
    if (this.leaderPosition) {
      return this.lineUpData?.positions.filter(
        (elem) => elem.name !== this.leaderPosition
      );
    }

    return this.lineUpData?.positions;
  }
}
