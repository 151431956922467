import { Component, Directive, Input, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { NgIf, NgClass } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { CardModule } from 'primeng/card';

@Component({
    selector: 'air-card',
    templateUrl: './air-card.component.html',
    styleUrls: ['./air-card.component.scss'],
    standalone: true,
    imports: [CardModule, SharedModule, NgIf, NgClass]
})
export class AirCardComponent implements OnInit {
  @Input() leftContent: Directive | undefined;
  @Input() rightContent: Directive | undefined;
  @Input() headerContent: Directive | undefined;
  @Input() footerContent: Directive | undefined;
  @Input() headerTitle: String | undefined;
  @Input() centerHeaderTitle: Boolean = false;

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {}
}
