<ng-container *ngIf="isLoading">
  <div style="text-align: center">😉</div>
</ng-container>

<air-main-layout *ngIf="!isLoading">
  <div class="grid w-full mb-3">
    <div class="col">
      <air-card>

        <p-treeTable #lineUpTable [value]="content" [columns]="cols" styleClass="p-treetable-sm">

          <ng-template pTemplate="caption">
            <div style="text-align: right">
              <span class="p-input-icon-right">
                <i class="pi pi-search"></i>
                <input type="text" placeholder="Filter" (input)="filter(lineUpTable, 'contains', $event)">
              </span>
            </div>
          </ng-template>

          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [class.text-center]="col.field === 'rank'">
                {{col.header}}
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
            <tr [class.section]="!rowData.name" [class.member]="rowData.name" (click)="showMember(rowData.name)">
              <td *ngFor="let col of columns; let i = index" [class.font-bold]="col.field === 'name'">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                {{ getFieldValue(col.field, rowData[col.field]) }}

                <div *ngIf="isRank(col.field, rowData[col.field])" class="flex justify-content-center">
                  <img
                    style="width: 32px; height: 32px;"
                    [class]="'air-image air-rank-' + rowData[col.field]"
                    [ngSrc]="getRankIcon(rowData[col.field])"
                    [alt]="getRankTranslation(rowData[col.field]).name || rowData[col.field]"
                    [pTooltip]="getRankTranslation(rowData[col.field]).name || rowData[col.field]"
                    [class.grayscale]="isDarkTheme()"
                    tooltipPosition="top"
                    loading="lazy"
                    fill=""/>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="summary">
            <div style="text-align:left">
              Mitglieder: {{ memberCount }}<br>
              <!-- Frei: {{ openPositionCount }} -->
            </div>
          </ng-template>

        </p-treeTable>
      </air-card>
    </div>
  </div>
</air-main-layout>
