import {Injectable} from '@angular/core';
import {LocalstorageService} from '../localstorage-service/localstorage.service';

@Injectable()
export class ExternalContentService {

  protected isAllContentAllowed = false;

  constructor(protected localStorageService: LocalstorageService) {
  }

  buildLocalstorageItems() {

    if (!this.localStorageService.getItem('isAllExternalContentAllowed')) {
      this.localStorageService.setItem('isAllExternalContentAllowed', 'false');
      this.isAllContentAllowed = false;
    }

    if (!this.localStorageService.getItem('allowedExternalContent')) {
      this.localStorageService.setItem('allowedExternalContent', '[]');
    }
  }

  allowExternalContent(type: string) {

    const allowedContent = JSON.parse(<string>this.localStorageService.getItem('allowedExternalContent')) || [];
    if (!allowedContent.includes(type)) {
      this.localStorageService.setItem('allowedExternalContent', JSON.stringify([...allowedContent, type]));
    }
  }

  allowAllExternalContent() {
    this.localStorageService.setItem('isAllExternalContentAllowed', 'true');
  }

  isAllExternalContentAllowed() {
    return this.localStorageService.getItem('isAllExternalContentAllowed') === 'true';
  }

  isAnyExternalContentAllowed() {
    return JSON.parse(<string>this.localStorageService.getItem('allowedExternalContent'))?.length > 0 || this.isAllExternalContentAllowed();
  }

  getAllowedExternalContent(): string[] {
    return JSON.parse(<string>this.localStorageService.getItem('allowedExternalContent')) || []
  }

  checkIsContentAllowed(type: string) {

    if (this.localStorageService.getItem('isAllExternalContentAllowed') === 'true') {
      return true;
    }

    const allowedContent = JSON.parse(<string>this.localStorageService.getItem('allowedExternalContent')) || [];
    return allowedContent.includes(type)
  }

  removeAllPermissions() {
    this.localStorageService.setItem('isAllExternalContentAllowed', 'false');
    this.localStorageService.setItem('allowedExternalContent', '[]');
  }

  removePermission(name: string) {
    const allowedContent = JSON.parse(<string>this.localStorageService.getItem('allowedExternalContent')) || [];

    if (allowedContent) {
      const filteredAllowedContent = allowedContent.filter((allowedContent: string) => allowedContent !== name);
      this.localStorageService.setItem('allowedExternalContent', JSON.stringify(filteredAllowedContent));
    }
  }
}
