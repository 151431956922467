import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {ManagementBadge} from "../profile-page/config/management-badge";
import {MemberData} from "../profile-page/config/member-data";
import {UrlService} from "../../service/url-service/url.service";
import {environment} from "../../../../environments/environment";
import {SEOService} from "../../service/seo-service/seo.service";
import {ManagementBadgeStatistic} from "../../types/management-badge-statistic";
import {AirborneTranslationService} from "../../service/airborne-translation-service/airborne-translation.service";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {LoadingComponent} from "../../shared/loading/loading.component";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";


@Component({
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, RouterLink, NgOptimizedImage, LoadingComponent, MainLayoutComponent, AirCardComponent],
  selector: 'app-additional-badge-page',
  templateUrl: './additional-badge-page.component.html',
  styleUrls: ['./additional-badge-page.component.scss']
})
export class AdditionalBadgePageComponent implements OnInit {

  readonly #route = inject(ActivatedRoute);
  readonly #urlService = inject(UrlService);
  readonly #router = inject(Router);
  readonly #seoService = inject(SEOService);
  readonly #airborneTranslationService = inject(AirborneTranslationService);


  isLoading = true;

  badgeData: ManagementBadgeStatistic;

  ngOnInit(): void {

    this.#urlService.setPreviousUrl(this.#router.url);

    this.badgeData = this.#route.snapshot.data['additionalBadgeData'];

    this.#seoService.setPageTitle(this.#airborneTranslationService.getBadgeTranslation(this.badgeData.badge.badge).name);
    this.#seoService.updateKeyWords(['badge', 'abzeichen'])

    this.isLoading = false;
  }


  getRankIconPath(member: MemberData) {

    if (member.zeusType === 'PERMANENT') {
      return 'assets/images/ranks/MSG.svg';
    }

    return 'assets/images/ranks/' + member.rankIdentCode + '.svg';
  }

  getAdditionalBadgePath(badge: ManagementBadge): string {
    // TODO rh meh, dafür gibts einen eigenen endpoint, der aber nur mit id aufgerufen werden kann
    return `${environment.baseAdcmUrl}/public-assets/badges/additionalBadges/${badge.badge}.png`;
  }

  getBadgeTranslation(badge: string) {
    return this.#airborneTranslationService.getBadgeTranslation(badge);
  }
}
