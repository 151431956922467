import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {WikiPageContent} from '../../pages/blog-page/config/wiki-page-content';
import {Observable} from 'rxjs';
import {WikiCategoryResult} from '../../pages/blog-page/config/wiki-category-result';

@Injectable()
export class WikiRepository {

  constructor(protected http: HttpClient) {}

  getModelName() {
    return ''
  }

  getLatestBlogEntries(): Observable<WikiCategoryResult> {

    return this.http.get<WikiCategoryResult>(
      environment.wikiUrl + '/api.php?action=query&list=categorymembers&cmtitle=Category:Blog&cmlimit=20&format=json&origin=*'
    );
  }

  getPagesWithExcerpt(searchParam: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin':'*',
      })
    };
    return this.http.get(
      environment.wikiUrl + `/rest.php/v1/search/page?q=${searchParam}&limit=20&exintro`,
    );

  }

  getPageContent(pageName: string): Observable<WikiPageContent> {

    return this.http.get<WikiPageContent>(
      environment.wikiUrl + `/api.php?action=parse&page=${pageName}&prop=text&formatversion=2&format=json&disabletoc=1&disableeditsection=1&origin=*&&disablestylededuplication=1&preview=1`
    );
  }
}
// https://en.wikipedia.org/w/api.php?action=parse&format=json&page=Pet_door&prop=text%7Cproperties&pst=1&disableeditsection=1&disablestylededuplication=1&preview=1&disabletoc=1&mobileformat=1&mainpage=1&formatversion=2
