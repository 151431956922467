import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {TreeNode} from 'primeng/api';
import AdcmRepository from '../../repositories/adcm/adcm.repository';
import {lastValueFrom} from 'rxjs';
import LineUpGroup from '../../types/lineup-group.type';
import LineUpPosition from '../../types/lineup-position.type';
import {TreeTable, TreeTableModule} from 'primeng/treetable';
import {ThemeService} from '../../service/theme-service/theme.service';
import { AirborneTranslationService } from '../../service/airborne-translation-service/airborne-translation.service';

import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {TooltipModule} from "primeng/tooltip";
import {MainLayoutComponent} from "../../shared/layouts/main-layout/main-layout.component";
import {AirCardComponent} from "../../shared/air-card/air-card.component";

@Component({
  standalone: true,
  imports: [
    TreeTableModule,
    NgForOf,
    NgOptimizedImage,
    TooltipModule,
    NgIf,
    MainLayoutComponent,
    AirCardComponent,
  ],
  selector: 'app-lineup2-page',
  templateUrl: './lineup2-page.component.html',
  styleUrls: ['./lineup2-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Lineup2PageComponent implements OnInit {

  // TODO
  // Farben im DarkMode anpassen
  // Nicht besetzte Positionen auszeichnen
  // Bei den Rekruten fehlen irgendwie noch Leute
  // CHQ, Platoons, Zeuse und Rekruten jeweils in eigene Tabelle zur Abtrennung

  readonly #cd = inject(ChangeDetectorRef);
  readonly #adcmRepo = inject(AdcmRepository)
  readonly #themeService = inject(ThemeService);
  readonly #airborneTranslationService = inject(AirborneTranslationService);

  cols: any[];
  content: TreeNode[] = [];
  isLoading = true;

  memberCount = 0;
  openPositionCount = 0;


  async ngOnInit() {
    this.cols = [
      {field: 'pos', header: 'Position'},
      {field: 'name', header: 'Name'},
      {field: 'rank', header: 'Rang'},
    ];

    await this.mapAllGroupsToTreeTable();
    // await this.mapCompanyToTreeTable();
  }

  async mapAllGroupsToTreeTable() {

    const {company, zeus, recruits} = await lastValueFrom(this.#adcmRepo.getCurrentLineUp());

    const mappedCompany = this.getCompanyGroup(company);
    const mappedZeus = this.getOtherGroup(zeus, 'ZEUS');
    const mappedRecruits = this.getOtherGroup(recruits, 'Rekruten')

    console.log('mappedCompany', mappedCompany)

    this.content = [
      mappedCompany,
      mappedZeus,
      mappedRecruits,
    ];

    this.isLoading = false;
    this.#cd.markForCheck();
  }

  protected async mapCompanyToTreeTable() {

    const lineUpData = await lastValueFrom(this.#adcmRepo.getLineUpData());

    for (const data of lineUpData) {

      const group = this.getCompanyGroup(data);

      this.content.push(group);
    }
  }

  protected getCompanyGroup(data: LineUpGroup | undefined): TreeNode {

    if (!data) {
      return {};
    }

    const group: TreeNode = {
      data: {
        pos: data.name,
        name: undefined,
        rank: undefined
      },
      expanded: true,
      children: this.getCompanyChildren(data),
    };

    return group;
  }

  protected getOtherGroup(data: any[] | undefined, name: string): TreeNode {

    if (!data) {
      return {};
    }

    const group: TreeNode = {
      data: {
        pos: name,
        name: undefined,
        rank: undefined
      },
      expanded: true,
      children: this.getOtherGroupChildren(data),
    };

    return group;
  }

  protected getCompanyPosition(position: LineUpPosition): TreeNode {

    const mappedPosition: TreeNode = {
      data: {
        pos: position.name,
        name: position.member?.name,
        rank: position.member?.rankIdentCode
      },
      expanded: true,
    };

    if (mappedPosition.data.name) {
      this.memberCount++;
    } else {
      this.openPositionCount++;
    }

    return mappedPosition;
  }

  protected getCompanyChildren(data: LineUpGroup): TreeNode[] {

    const children: TreeNode[] = [];

    if (data.positions?.length > 0) {

      for (const position of data.positions) {

        const mappedPosition = this.getCompanyPosition(position);
        if (!mappedPosition.data.name || !mappedPosition.data.pos) continue;
        children.push(mappedPosition);
      }

    }

    if (data.children?.length > 0) {

      for (const group of data.children) {

        const mappedGroup = this.getCompanyGroup(group);
        children.push(mappedGroup);
      }

    }

    return children;
  }

  protected getOtherGroupChildren(data: any[]) {

    if (!data) {
      return [];
    }

    const mappedPositionList: TreeNode[] = [];

    if (data.length > 0) {

      for (const position of data) {

        const mappedPosition: TreeNode = {
          data: {
            pos: undefined,
            name: position.name,
            rank: position.rankIdentCode
          },
          expanded: true,
        };

        if (mappedPosition.data.name) {
          this.memberCount++;
        } else {
          this.openPositionCount++;
        }

        mappedPositionList.push(mappedPosition);
      }

      return mappedPositionList;
    }

    return [];
  }

  filter(table: TreeTable, contains: string, $event: Event) {

    if (!$event) {
      return;
    }

    const target = $event.target as HTMLInputElement;
    table.filterGlobal(target.value, contains);
  }

  showMember(name: string) {

    if (!name) {
      return;
    }

    console.log('SHOW MEMBER:', name)
    return false;
  }

  isRank(fieldType: string, fieldValue: string) {

    if (fieldType !== 'rank') {
      return;
    }

    const ranks = ['PV1', 'PV2', 'PFC', 'SPC', 'CPL', 'SGT', 'SSG', 'SFC', 'MSG', '1SG', '2LT', '1LT', 'CPT', 'ZEUS', 'MSG'];

    return ranks.includes(fieldValue);
  }

  getRankIcon(fieldValue: string) {
    if (fieldValue === 'ZEUS') {
      return 'assets/images/ranks/MSG.svg';
    }

    return 'assets/images/ranks/' + fieldValue + '.svg';
  }

  isDarkTheme() {
    return this.#themeService.getCurrentThemeNameShort() === 'dark';
  }

  getFieldValue(fieldType: string, fieldValue: string) {

    if (fieldType === 'rank') {
      return;
    }

    return fieldValue;
  }

  getRankTranslation(rank: string) {
    return this.#airborneTranslationService.getRanksTranslation(rank);
  }
}
